import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, EffectFade } from 'swiper';

import { styled } from '@mui/material';

import { AnimationControls } from './animation-controls';
import classes from './image-swiper.module.scss';
import { SlideData } from './types';

type AnimatedImagesProps = {
  slides: SlideData[];
  fadeDelay?: number;
};

export const FadeImages = ({
  slides,
  fadeDelay = 3000,
}: AnimatedImagesProps) => {
  return (
    <SwiperWrapper
      className={classes.imageSwiper}
      modules={[EffectFade, Autoplay]}
      effect="fade"
      loop
      autoplay={{
        delay: fadeDelay,
      }}
    >
      {slides.map((slide, idx) => (
        <SwiperSlide key={idx}>{slide.image}</SwiperSlide>
      ))}

      <Controls>
        <AnimationControls />
      </Controls>
    </SwiperWrapper>
  );
};

const SwiperWrapper = styled(Swiper)(({ theme }) => ({
  position: 'relative',
  aspectRatio: '3 / 2',
}));

const Controls = styled('div')(({ theme }) => ({
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  left: '50%',
}));
