import { styled } from '@mui/material';

export type AwardData = {
  title: string;
  description: string;
  image: React.ReactElement;
};

export const Award = ({ award }: { award: AwardData }) => (
  <AwardContainer>
    <ImageContainer>{award.image}</ImageContainer>
    <Title>{award.title}</Title>
    <Description>{award.description}</Description>
  </AwardContainer>
);

const AwardContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '180px auto 1fr',

  [theme.breakpoints.up('md')]: {
    gridTemplateRows: '230px auto 1fr',
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateRows: '280px auto 1fr',
  },
}));

const ImageContainer = styled('div')(({ theme }) => ({
  '.gatsby-image-wrapper': {
    width: '200px',
    height: '150px',

    [theme.breakpoints.up('md')]: {
      height: '200px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '250px',
    },

    img: {
      objectPosition: 'left',
    },
  },
}));

const Title = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  margin: '0 0 10px',
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  justifySelf: 'center',
  margin: 0,
}));
