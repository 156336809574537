import { RefObject, useCallback, useEffect, useRef } from 'react';

export const useTabGlider = (
  tabsContainer: RefObject<HTMLDivElement>,
  activeIndex: number,
) => {
  const oldTab = useRef<HTMLElement | null>(null);

  // move underline indicator
  const moveIndicator = useCallback(
    (index: number) => {
      const closeTab =
        oldTab.current ??
        (tabsContainer.current?.firstElementChild as HTMLElement);

      const openTab = tabsContainer.current?.children[
        index ?? 0
      ] as HTMLElement;

      // Activate the new tab to calculate the size of the indicator line
      removeTransitions(openTab, closeTab);
      activateTab(openTab, closeTab);

      if (closeTab && openTab) {
        if (tabsContainer.current) {
          const newTabWidth =
            openTab.offsetWidth / tabsContainer.current.offsetWidth;

          tabsContainer.current.style.setProperty(
            '--_left',
            openTab.offsetLeft + 'px',
          );
          tabsContainer.current.style.setProperty(
            '--_width',
            newTabWidth.toString(),
          );
          tabsContainer.current.style.setProperty('--_show', 'block');

          // Reactivate the old tab to perform the tab switching animation
          activateTab(closeTab, openTab);

          requestAnimationFrame(() => {
            addTransitions(openTab, closeTab);
            activateTab(openTab, closeTab);
          });
        }
      }
    },
    [tabsContainer],
  );

  const switchTab = useCallback(
    (index: number) => {
      requestAnimationFrame(() => {
        moveIndicator(index);
        oldTab.current = tabsContainer.current?.children[
          index ?? 0
        ] as HTMLElement;
      });
    },
    [moveIndicator, tabsContainer],
  );

  const resize = useCallback(() => {
    switchTab(activeIndex);
  }, [switchTab, activeIndex]);

  useEffect(() => {
    switchTab(activeIndex);
  }, [activeIndex, switchTab]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  return { switchTab };
};

const activateTab = (activeTab: HTMLElement, inactiveTab: HTMLElement) => {
  activeTab?.classList.add('active');

  if (activeTab !== inactiveTab) {
    inactiveTab.classList.remove('active');
  }
};

const removeTransitions = (...tabs: HTMLElement[]) => {
  tabs.forEach((tab) => {
    tab?.style.setProperty('transition', 'none');
  });
};

const addTransitions = (...tabs: HTMLElement[]) => {
  tabs.forEach((tab) => {
    tab.style.setProperty(
      'transition',
      'color var(--transition-duration), font var(--transition-duration)',
    );
  });
};
