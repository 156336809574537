import React from 'react';

import { styled } from '@mui/material';

import { Tag } from '../tags/tags';
import { ServiceSegmentsTheme, Tags } from './types';

export const ServiceSegmentsTags = <T extends React.ElementType>({
  category,
  tags,
  theme = 'dark',
  tagsComponent,
}: {
  category: string;
  tags: Tags<T>[];
  theme: ServiceSegmentsTheme;
  tagsComponent: T;
}) => {
  return (
    <Wrapper>
      <Headline
        sx={{
          color: theme === 'dark' ? '#fff' : '#000',
        }}
      >
        {category}
      </Headline>
      <TagWrapper>
        {tags.map((tag) => (
          <Tag
            key={tag.label}
            tagTheme={theme}
            link={{
              component: tagsComponent,
              props: tag.props,
            }}
          >
            {tag.label}
          </Tag>
        ))}
      </TagWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  marginBottom: '40px',
}));

const TagWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: '10px',
}));

const Headline = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  color: 'white',
  margin: '25px 0',
}));
