import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperClass from 'swiper';
import 'swiper/css';
import 'swiper/css/bundle';

import { Button, ButtonTypeMap, Card, styled } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import { ArrowLeftIcon, ArrowRightIcon } from '../../../../assets/icons';
import FadeText from '../../text/fade-text';
import { ResponsiveImageProps } from '../../types';
import CardSlide from '../core/card-slide';
import cssVars from './../../../styles/export-variables.module.scss';
import styles from './product-card-color-slider.module.scss';

export type ProductCardSlideProps = React.ComponentProps<typeof Card> & {
  title?: string;
  subtitle?: string;
  variants: Variant[];
  className?: string;
  ImageLinkComponent?: React.ElementType;
};

export type Variant = {
  image: React.ReactElement<ResponsiveImageProps>;
  colorName: string;
  footerButtonProps: OverrideProps<ButtonTypeMap, React.ElementType>;
  imageLinkProps?: unknown;
};

export const ProductCardColorSlider = ({
  title,
  subtitle,
  variants,
  className = '',
  ImageLinkComponent,
}: ProductCardSlideProps) => {
  const [thumbImage, setThumbImage] = useState<SwiperClass | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const swiperSpeed: number = parseInt(cssVars.transitionDuration);
  const slidesPerView = 3;

  const thumbSlideChanged = (swiper: SwiperClass) => {
    setSelectedIndex(swiper.realIndex);
  };

  const VariantLink = ImageLinkComponent || 'a';

  const productLinkArea = (
    <>
      <div className={styles.mainSwiper}>
        {/* NOTE: Here we render all variant images with an absolute position
        and just fade in the active one. This is not really a good solution,
        but seems to be more performant that using the Swiper with a fade
        transition. Ideally we don't use a Swiper but still just render the
        images which are needed to perform the transition instead of all the
        variant images.*/}
        {variants.map((v, idx) => (
          <div
            key={idx}
            className={styles.imageWrapper}
            style={{
              opacity: idx === selectedIndex ? 1 : 0,

              // Put active image on top to make sure "open image in new tab"
              // is still working correctly.
              zIndex: idx === selectedIndex ? 1 : 0,

              // Don't display inactive images to exclude them from the layout
              // calculation and increase rendering performance.
              display: Math.abs(idx - selectedIndex) > 1 ? 'none' : 'block',
            }}
          >
            {React.cloneElement(v.image, {
              className: styles.mainImage,
            })}
          </div>
        ))}
      </div>
      <div className={styles.productNameContainer}>
        <ProductName>{title}</ProductName>
        <CollectionName>{subtitle}</CollectionName>
      </div>
    </>
  );

  const hasProductDetails = variants.every((v) => !!v.imageLinkProps);
  const showArrows = variants.length > 3;

  return (
    <CardSlide className={className}>
      <Card variant="outlined" className={styles.productCard}>
        {hasProductDetails ? (
          <VariantLink
            {...(variants[selectedIndex].imageLinkProps ?? {})}
            className={styles.variantLink}
          >
            <div className={styles.productLinkArea}>{productLinkArea}</div>
          </VariantLink>
        ) : (
          <div className={styles.productLinkArea}>{productLinkArea}</div>
        )}

        {variants.length >= 2 && (
          <>
            {showArrows && (
              <button
                className={`${styles.navPrevious} ${styles.onCardHover}`}
                onClick={() => {
                  thumbImage?.slidePrev();
                }}
              >
                <ArrowLeftIcon className={styles.navIcons} />
              </button>
            )}
            <Swiper
              slidesPerView={slidesPerView}
              loop={variants.length >= 3}
              speed={swiperSpeed}
              centeredSlides={true}
              className={`${styles.thumbContainer} ${styles.onCardHover}`}
              onSlideChange={thumbSlideChanged}
              slideToClickedSlide={true}
              onSwiper={setThumbImage}
              allowTouchMove={false}
            >
              {variants.map((v, i) => (
                <SwiperSlide key={i}>
                  {React.cloneElement(v.image, {
                    className: styles.thumbImage,
                  })}
                </SwiperSlide>
              ))}
            </Swiper>
            {showArrows && (
              <button
                className={`${styles.navNext} ${styles.onCardHover}`}
                onClick={() => {
                  thumbImage?.slideNext();
                }}
              >
                <ArrowRightIcon className={styles.navIcons} />
              </button>
            )}
            <div className={`${styles.colorLabel} ${styles.onCardHover}`}>
              <ColorName text={variants[selectedIndex]?.colorName || ''} />
            </div>
          </>
        )}
        {hasProductDetails && (
          <div className={styles.buttonContainer}>
            <Button
              variant="text"
              size="small"
              className={styles.showDetails}
              {...variants[selectedIndex].footerButtonProps}
            />
          </div>
        )}
      </Card>
    </CardSlide>
  );
};
ProductCardColorSlider.displayName = 'SwiperSlide';

const ProductName = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'center',
  margin: '0',
}));

const CollectionName = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  fontWeight: theme.typography.fontWeightRegular,
  opacity: '0.7',
  textAlign: 'center',
  margin: '0',
}));

const ColorName = styled(FadeText)(({ theme }) => ({
  ...theme.typography.copy,
  fontWeight: theme.typography.fontWeightRegular,
  color: 'var(--color-medium-grey)',
  textAlign: 'center',
  margin: '0',
}));
