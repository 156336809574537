import React from 'react';

import { CardSlider } from '../core/card-slider';
import styles from './lookbook-slider.module.scss';

export type LookbookSliderProps = {
  children?: React.ReactNode;
};

export const LookbookSlider: React.FC<LookbookSliderProps> = ({ children }) => {
  return (
    <CardSlider
      className={styles.lookbookSlider}
      navigationStyling={{ fillMode: 'outlined-white', color: 'dark-coal' }}
      navigationHoverStyling={{
        fillMode: 'outlined-white',
        color: 'light-red',
      }}
    >
      {children}
    </CardSlider>
  );
};

export default LookbookSlider;
