import { Components, Theme } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { filledInputClasses } from '@mui/material/FilledInput';
import { paperClasses } from '@mui/material/Paper';
import { textFieldClasses } from '@mui/material/TextField';

import { SelectArrowDownIcon } from '../../assets/icons';

const autocomplete: Components<Theme>['MuiAutocomplete'] = {
  defaultProps: {
    disablePortal: true,
    popupIcon: <SelectArrowDownIcon />,
  },
  styleOverrides: {
    root: {
      [`.${textFieldClasses.root} .${filledInputClasses.root}`]: {
        paddingTop: '17px',
        [`.${filledInputClasses.input}`]: {
          paddingTop: '7px',
          paddingLeft: '8px',
        },

        [`.${autocompleteClasses.endAdornment}`]: {
          right: '20px',
        },
      },
    },
    popper: {
      // make sure the popper always stays below the textfield.
      inset: '0px auto auto 0px !important',
      transform: 'translate3d(0px, 55px, 0px) !important',
      width: '100% !important',

      // divider line
      '&::before': {
        content: '" "',
        position: 'absolute',
        backgroundColor: 'var(--color-medium-light-grey)',
        left: '16px',
        right: '20px',
        height: '1px',
        margin: 'auto',
      },

      [`.${paperClasses.root}`]: {
        boxShadow: 'none',

        [`.${autocompleteClasses.listbox}, .${autocompleteClasses.noOptions}`]:
          {
            border: '1px solid var(--color-medium-grey)',
            borderTop: 0,
          },
      },
    },
    // menu styling if no options are available
    noOptions: {
      [`.${paperClasses.root}`]: {
        boxShadow: 'none',

        [`.${autocompleteClasses.listbox}`]: {
          border: '1px solid var(--color-medium-grey)',
          borderTop: 0,
        },
      },
    },
    listbox: {
      [`.${autocompleteClasses.option}`]: {
        backgroundColor: 'white',
        [`&.${autocompleteClasses.focused}`]: {
          backgroundColor: 'var(--color-light-grey)',
          color: 'var(--color-light-red)',
        },
      },
      [`.${autocompleteClasses.option}[aria-selected="true"]`]: {
        color: 'var(--color-light-red)',
        backgroundColor: 'white',

        [`&.${autocompleteClasses.focused}`]: {
          backgroundColor: 'var(--color-light-grey)',
        },
      },
    },
  },
};

export default autocomplete;
