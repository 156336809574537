import React from 'react';

import styles from './slider-button.module.scss';

/**
 * ! Fillmode: Classnames are derived from (and therefore tightly coupled to) these option strings.
 * ! Color: Classnames are derived from these options which must match the CSS color variable name.
 */
export type SliderButtonStyle = {
  fillMode: 'outlined' | 'contained' | 'outlined-white';
  color: 'light-red' | 'dark-red' | 'dark-coal' | 'white' | 'light-blue';
};

export type SliderButtonProps = React.HTMLProps<HTMLButtonElement> & {
  styling: SliderButtonStyle;
  hoverStyling: SliderButtonStyle;
  className?: string;
  onClick?: () => void;
  mobileSmall?: boolean;
};

export const RoundButton = React.forwardRef<
  HTMLButtonElement,
  SliderButtonProps
>(
  (
    {
      styling,
      hoverStyling,
      className = '',
      onClick,
      children: icon,
      style,
      disabled,
      mobileSmall,
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        style={style}
        className={`${styles.navButton} ${className} ${styling.fillMode} ${
          styling.color
        } hover-${hoverStyling.fillMode} hover-${hoverStyling.color} ${
          mobileSmall ? styles.navButtonMobileSmall : ''
        }`}
        onClick={onClick}
      >
        {icon}
      </button>
    );
  },
);

// The left-arrow is just a rotated right-arrow
export const SliderButtonPrev = React.forwardRef<
  HTMLButtonElement,
  SliderButtonProps
>((props, ref) => (
  <RoundButton {...props} ref={ref}>
    <ArrowLargeRightIcon
      className={`${styles.navIcon} ${styles.navIconDesktop} ${styles.left}`}
    />
    {props.mobileSmall && (
      <ArrowLargeRightIconSmall
        className={`${styles.navIcon} ${styles.navIconMobile} ${styles.left}`}
      />
    )}
  </RoundButton>
));

export const SliderButtonNext = React.forwardRef<
  HTMLButtonElement,
  SliderButtonProps
>((props, ref) => (
  <RoundButton {...props} ref={ref}>
    <ArrowLargeRightIcon
      className={`${styles.navIcon} ${styles.navIconDesktop}`}
    />
    {props.mobileSmall && (
      <ArrowLargeRightIconSmall
        className={`${styles.navIcon} ${styles.navIconMobile}`}
      />
    )}
  </RoundButton>
));

export const SliderButtonPause = React.forwardRef<
  HTMLButtonElement,
  SliderButtonProps
>((props, ref) => (
  <RoundButton {...props} ref={ref}>
    <PauseIcon className={`${styles.navIcon} ${styles.navIconDesktop}`} />
  </RoundButton>
));

const PauseIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="fill stroke-inner"
      cx="35"
      cy="35"
      r="34"
      strokeWidth="2"
    />
    <path
      className="stroke-inner"
      strokeWidth="2"
      d="M40.787 23.429v23.175M29.213 23.429v23.175"
    />
  </svg>
);

// We define the svg icon as react component directly because importing the svg
// in the gatsby project causes the class names to be changed (maybe because of
// the CSS modules loader?). This does not happen in the storybook project.
// Defining the svg here gives a consistent behaviour.
const ArrowLargeRightIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="fill stroke-inner"
      cx="35"
      cy="35"
      r="34"
      strokeWidth="2"
    />
    <path
      className="stroke-inner"
      d="M30.2578 23L41.3298 34.072L30.2578 45.143"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

const ArrowLargeRightIconSmall = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="stroke-outer fill"
      cx="20"
      cy="20"
      r="19"
      fill="transparent"
      strokeWidth="2"
    />
    <path
      className="stroke-inner"
      d="M17.2891 13.7144L23.6159 20.0412L17.2891 26.3675"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);
