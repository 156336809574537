import { Components, Theme } from '@mui/material';

const CheckboxIcon = ({ checked }: { checked?: boolean }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="2"
      width="20"
      height="20"
      rx="0.5"
      fill="white"
      stroke="var(--color-medium-grey)"
      className="mewaCheckboxRect"
    />
    {checked && (
      <path
        d="M9.62854 15.3016L6.32729 12.0004L5.20312 13.1166L9.62854 17.542L19.1285 8.04203L18.0123 6.92578L9.62854 15.3016Z"
        fill="#E60021"
      />
    )}
  </svg>
);

const checkbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
    icon: <CheckboxIcon />,
    checkedIcon: <CheckboxIcon checked />,
    tabIndex: 0,
  },
  styleOverrides: {
    root: {
      padding: '8px 10px 8px 11px',

      'svg .mewaCheckboxRect': {
        transition: 'stroke 200ms var(--transition-timing)',
      },

      '&:hover, &:focus, &.Mui-focusVisible': {
        'svg .mewaCheckboxRect': {
          stroke: 'var(--color-dark-coal)',
          strokeWidth: '2',
        },
      },
    },
  },
};

export default checkbox;
