import React from 'react';

import { Components, StepIconProps, styled, Theme } from '@mui/material';

export const stepper = (theme: Theme): Components<Theme>['MuiStepper'] => ({
  styleOverrides: {
    root: {
      '& .MuiStepConnector-line': {
        borderColor: 'var(--color-dark-coal)',
        opacity: '0.3',
      },
    },
  },
});

export const step = (theme: Theme): Components<Theme>['MuiStep'] => ({
  styleOverrides: {
    root: {
      padding: '0',
    },
  },
});

export const stepLabel = (theme: Theme): Components<Theme>['MuiStepLabel'] => ({
  defaultProps: {
    StepIconComponent: CustomStepIcon,
  },
  styleOverrides: {
    iconContainer: {
      padding: '0',
    },
  },
});

const StyledStepIcon = styled('div')(
  ({ theme }) => `
  font-size: ${theme.typography.copySm.fontSize};
  font-weight: ${theme.typography.fontWeightMedium};
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  position: relative;

  color: var(--color-dark-coal);
  opacity: .5;

  /* Circle has own element due to different opacities */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 100%;
    border: 1px solid currentColor;

    /*
     * The design specifies an opacity of 0.3, but this opacity is multiplied
     * with the parent's opacity --> 0.5 * 0.6 = 0.3
     */
    opacity: .6;
  }

  &.active {
    color: var(--color-white);
    opacity: 1;

    &:before {
      border: none;
      background-color: var(--color-light-red);
      opacity: 1;
      z-index: -1;
    }
  }

  &.completed {
    color: var(--color-white);
    opacity: 1;

    &:before {
      border: none;
      background-color: var(--color-medium-grey);
      opacity: 1;
      z-index: -1;
    }
  }

`,
);

const CustomStepIcon: React.FC<StepIconProps> = ({
  icon,
  active,
  completed,
  ...rest
}) => {
  return (
    <StyledStepIcon
      className={active ? 'active' : completed ? 'completed' : ''}
    >
      {icon}
    </StyledStepIcon>
  );
};
