import { PropsWithChildren } from 'react';

import { styled, Typography, TypographyTypeMap } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

export type TagsTheme = 'light' | 'dark' | 'white';

export type TagProps<T extends React.ElementType> = {
  className?: string;
  onClick?: () => void;
  tagTheme: TagsTheme;

  link: {
    component: T;
    props: OverrideProps<TypographyTypeMap, T>;
  };
};

export const Tag = <T extends React.ElementType>({
  children,
  className = '',
  link,
  onClick,
  tagTheme = 'dark',
}: PropsWithChildren<TagProps<T>>) => (
  <StyledTypography
    component={link.component}
    className={`${className} is-theme-${tagTheme === 'dark' ? 'dark' : 'light'}`}
    {...link.props}
    onClick={onClick}
  >
    {children}
  </StyledTypography>
);

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.copySm,
  backgroundColor: 'var(--color-light-grey)',
  color: 'var(--color-dark-coal)',
  border: 'none',
  borderRadius: '20px',
  padding: '6px 18px 8px',
  fontWeight: theme.typography.fontWeightMedium,

  [theme.breakpoints.up('md')]: {
    padding: '6px 25px 9px',
  },

  '&.is-theme-light': {
    backgroundColor: 'var(--color-medium-light-grey)',
  },

  '&:hover': {
    backgroundColor: 'var(--color-medium-grey)',
    color: 'var(--color-white)',
    cursor: 'pointer',
  },
}));
