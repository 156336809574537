import { styled } from '@mui/material';

import { MbsIcon, MtsIcon } from '@mewa/ui';

export const ProductFallbackImage = ({
  useMbsFallbackIcon,
  className,
}: {
  useMbsFallbackIcon: boolean;
  className?: string;
}) =>
  useMbsFallbackIcon ? (
    <MbsIconStyled className={className} />
  ) : (
    <MtsIconStyled className={className} />
  );

const MbsIconStyled = styled(MbsIcon)({
  fill: 'var(--color-medium-light-grey)',
});

const MtsIconStyled = styled(MtsIcon)({
  fill: 'var(--color-medium-light-grey)',
});
