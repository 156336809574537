import React from 'react';

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { styled, SxProps } from '@mui/material';

type Props = {
  alt: string;
  images: ImageData[];
  style?: SxProps;
  // For Styled-Components
  className?: string;
  intendedForSearchIndex?: boolean;
  objectFit?: React.CSSProperties['objectFit'];
};

export type ImageData = {
  // Path to the image
  src: string;

  // Media query for which the image should be used.
  // e.g. `(max-width: 600px)`
  media: string;

  // Width in px of the original image
  width: number;

  // Width in px of the original image
  height: number;
};

/**
 * You can use this component if you want to manually define different images
 * for different viewports.
 *
 * Also see https://www.gatsbyjs.com/plugins/gatsby-plugin-image to learn more
 * about the `gatsby-plugin-image`.
 */
const Image: React.FC<Props> = React.memo(
  ({
    alt,
    images,
    style,
    className,
    objectFit,
    intendedForSearchIndex = false,
  }) => {
    const sources = images.map((imgData) => ({
      srcSet: imgData.src,
      media: imgData.media,
    }));

    const gatsbyImageData: IGatsbyImageData = {
      width: images[0].width,
      height: images[0].height,
      layout: 'fullWidth',
      images: {
        sources: sources,
        fallback: {
          src: images[0].src,
        },
      },
    };

    // By default, gatsby image only supports a single aspect ratio. This is fine if
    // we want to load different image resolutions with the same aspect ratio.
    // In our case though we need to support different aspect rations for
    // different images.
    // GatsbyImage uses the first empty child div to define the image aspect
    // ratio via a 'paddingTop'. We overwrite this padding based on the passed in
    // media query and image sizes.
    const mediaStyles = images.map((imgCfg) => [
      '@media screen and ' + imgCfg.media,
      {
        paddingTop: `${(imgCfg.height / imgCfg.width) * 100}% !important`,
      },
    ]);

    const paddingDivStyles = Object.fromEntries(mediaStyles);

    const imgStyles = {
      '> div:first-of-type': paddingDivStyles,
      ...style,
    };

    return (
      <MuiGatsbyImage
        alt={alt}
        image={gatsbyImageData}
        // For manual style overrides
        sx={imgStyles}
        // For Styled-Components
        className={className}
        data-pagefind-meta={
          intendedForSearchIndex ? 'image[data-src], image_alt[alt]' : undefined
        }
        data-pagefind-ignore={intendedForSearchIndex ? undefined : 'all'}
        objectFit={objectFit}
      />
    );
  },
);

// Wrap the GatsbyImage in a MUI `styled` call so we can use the `sx` property.
const MuiGatsbyImage = styled(GatsbyImage)({});

export default Image;
