import React, { useCallback } from 'react';

import classes from './sticky-header.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement>;

const setCSSVariable = (property: string, value: string) => {
  const root = document.querySelector<HTMLElement>(':root');
  root?.style.setProperty(property, value);
};

/**
 * The sticky header always scrolls out if the page scrolls down.
 * If the page scrolls up, the header scrolls in and is displayed until the page scrolls down.
 *
 * For this functionality we listen to the scroll event to figure out if the page scrolls
 * down or up (`lastScrollTop` vs. `currentScrollTop`).
 *
 * When the page scrolls up, the height of the header container is set to the current scrollTop.
 * So the sticky header scrolls in.
 *
 * When the page scrolls down, the height of the header container is set to the current scrollTop plus the headerHeight.
 * So the sticky header now scrolls out.
 */
export const StickyHeader: React.FC<Props> = ({
  children,
  className = '',
  ...rest
}) => {
  const lastScrollTop = React.useRef(0);
  const showHeader = React.useRef(false);

  const scrollListener = useCallback(() => {
    const currentScrollTop =
      window.scrollY || document.documentElement.scrollTop;

    if (
      currentScrollTop > lastScrollTop.current && // scroll down
      showHeader.current
    ) {
      setCSSVariable(
        '--header-sticky-position',
        'calc(-1 * var(--header-height))',
      );
      showHeader.current = false;
    } else if (
      currentScrollTop < lastScrollTop.current && // scroll up
      !showHeader.current
    ) {
      setCSSVariable('--header-sticky-position', '0px');
      showHeader.current = true;
    }
    // remember actual scroll position
    lastScrollTop.current = currentScrollTop <= 0 ? 0 : currentScrollTop; // For mobile or negative scrolling
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  return (
    <header className={`${classes.header} ${className}`} {...rest}>
      {children}
    </header>
  );
};

export default StickyHeader;
