type Config = {
  dropdownItemHeight?: number;
  dropdownButtonHeight?: number;
  dropdownPadding?: number;
  upperBoundOffset?: number;
};

export const scrollToDropdown =
  <T>(
    scrollToElementId: string,
    items: T[],
    {
      dropdownItemHeight = 38.8, // standard value for homepage + contact dropdown item
      dropdownButtonHeight = 119, // standard value for homepage dropdown button
      dropdownPadding = 50,
      upperBoundOffset = 100,
    }: Config,
  ) =>
  () => {
    if (scrollToElementId === null) {
      return;
    }

    const itemCount = items.length;
    const dropdownHeight = itemCount * dropdownItemHeight + dropdownPadding;
    const totalHeight = dropdownHeight + dropdownButtonHeight;

    const menuDimensions = document
      .getElementById(scrollToElementId)
      ?.getBoundingClientRect();

    if (menuDimensions === undefined) {
      return;
    }

    const lowerBound = menuDimensions.top + totalHeight;
    const upperBound = window.scrollY + menuDimensions.top - upperBoundOffset;

    if (lowerBound > window.innerHeight) {
      let scrollTargetPosition =
        lowerBound - window.innerHeight + window.scrollY;

      if (scrollTargetPosition > upperBound) {
        scrollTargetPosition = upperBound;
      }

      window.scrollTo({
        top: scrollTargetPosition,
        behavior: 'smooth',
      });
    }
  };
