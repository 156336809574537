import { styled } from '@mui/material';

import { GridWrapper } from '../../layout/grid';

export const ResponsiveProductGrid = styled(GridWrapper)(({ theme }) => ({
  marginTop: '35px',
  display: 'grid',
  gridTemplateColumns: '1fr',

  [theme.breakpoints.up(600)]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [theme.breakpoints.up(1200)]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '120px',
  },

  [theme.breakpoints.up(1600)]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  [theme.breakpoints.up(2000)]: {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
}));

export const ProductCardGridItem = styled('div')(() => ({
  width: 'calc(100% + 1px)',
  marginRight: '-1px',
  marginBottom: '-1px',
}));
