import { Components, styled, Theme } from '@mui/material';

const RadioIcon = ({ checked }: { checked?: boolean }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <OuterCircle
      cx="12"
      cy="12"
      r="11"
      className="mewaRadioButtonOuterCircle"
    />
    <InnerCircle
      style={{
        transform: `scale(${checked ? '1' : '0'})`,
      }}
      cx="12"
      cy="12"
      r="7"
    />
  </svg>
);

const OuterCircle = styled('circle')(({ theme }) => ({
  fill: theme.palette.background.default,
  stroke: 'var(--color-medium-grey)',
}));

const InnerCircle = styled('circle')(({ theme }) => ({
  transition: 'transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  transformOrigin: 'center',
  fill: theme.palette.primary.main,
}));

const radioButton: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    disableRipple: true,
    icon: <RadioIcon />,
    checkedIcon: <RadioIcon checked />,
    tabIndex: 0,
  },

  styleOverrides: {
    root: {
      '&:focus, &:hover, &:focus-within, &.Mui-focusVisible': {
        'svg .mewaRadioButtonOuterCircle': {
          stroke: 'var(--color-dark-coal)',
          strokeWidth: '2',
        },
      },
    },
  },
};

export default radioButton;
