import PiwikPro from '@piwikpro/react-piwik-pro';

import type { GatsbyBrowser } from 'gatsby';

import { registerScrollState } from '@mewa/ui';

import {
  LayoutPageContext,
  layoutWrapper,
  layoutWrapperWithDebugGrid,
  themeWrapper,
} from './gatsby-pages/page-wrapper';
import { NavigationData } from './src/components/header/types';
import { setPreviousLocationInternal } from './src/helpers/previous-location-state';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = themeWrapper;

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = (
  history,
) => {
  // We need to know globally that we are routing within our Gatsby site
  if (history.prevLocation !== null) {
    setPreviousLocationInternal();
  }
};

export const wrapPageElement: GatsbyBrowser<
  NavigationData,
  LayoutPageContext
>['wrapPageElement'] =
  process.env['NODE_ENV'] === 'development'
    ? layoutWrapperWithDebugGrid
    : layoutWrapper;

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  registerScrollState(document.body);

  PiwikPro.initialize(
    process.env['GATSBY_PIWIK_CONTAINER_ID'] ?? '',
    '//mewa.piwik.pro',
  );
};

/**
 * Tells gatsby if we should update the scroll position when navigating to a
 * new page. This implementation will update the scroll position by default
 * except for pages with the same base url and just a different suffix or
 * pages with exactly the same URL.
 *
 * This allows us to have a more 'app' like UX when using tabs to switch
 * between different pages.
 */
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = (
  args,
) => {
  const current = args.routerProps;
  const prev = args.prevRouterProps;

  // Don't update scroll position if pathname did not change (e.g. hash navigation).
  if (current.location.pathname === prev?.location.pathname) {
    return false;
  }

  // Don't update scroll position if path has same prefix (e.g. tab navigation)
  if (prev) {
    const curComponents = current.location.pathname.split('/');
    const prevComponents = prev.location.pathname.split('/');

    if (curComponents.length === prevComponents.length) {
      const curLast = curComponents[curComponents.length - 1];
      const prevLast = prevComponents[prevComponents.length - 1];

      for (let i = 0; i < curComponents.length - 1; i++) {
        if (curComponents[i] !== prevComponents[i]) {
          return true;
        }
      }

      const prefix = curLast.split('-')[0];

      if (prefix && prevLast.startsWith(prefix)) {
        return false;
      }
    }
  }

  return true;
};
