import React from 'react';

import { Grid2 as Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../../layout/grid';
import { ProductCardKeyFacts } from '../cards/product-card-key-facts';
import { FixedColumnSlider } from '../core/fixed-column-slider';
import { useSliderViewport, useSliderWidth } from '../core/viewport';

export type RelatedProduct = {
  id: string;
  modelName: string;
  slug: string;
  keyFacts: string[];
  variants: {
    assetId: string | null;
  }[];
};
type Props = {
  headline: string;
  Link: React.ElementType;
  products: (RelatedProduct & { image: React.ReactElement })[];
  langPrefix: string;
  ctaLabel: string;
  [key: `data-${string}`]: string;
};

export const RelatedProducts = ({
  headline,
  products,
  Link,
  langPrefix,
  ctaLabel,
  ...dataProps
}: Props) => {
  const sliderViewport = useSliderViewport();
  const columnsCount = useSliderWidth([
    { minWidth: 0, columns: 1.2 },
    { minWidth: 500, columns: 1.6 },
    { minWidth: 600, columns: 1.9 },
    { minWidth: 700, columns: 2.3 },
    { minWidth: 800, columns: 2.6 },
    { minWidth: 900, columns: 2 },
    { minWidth: 1024, columns: 2.3 },
    { minWidth: 1200, columns: 2.9 },
    { minWidth: 1300, columns: 3 },
  ]);

  return (
    <div {...dataProps}>
      <ResponsiveGrid>
        <Grid size={12}>
          <Headline>{headline}</Headline>
        </Grid>
      </ResponsiveGrid>
      <SliderWrapper>
        <FixedColumnSlider
          renderSlide={(product, index) => (
            <ProductCardKeyFacts
              key={index}
              image={product.image}
              keyFacts={product.keyFacts}
              productLink={{
                url: `${langPrefix}/${product.slug}`,
                label: ctaLabel,
                LinkComponent: Link,
              }}
              title={product.modelName}
            />
          )}
          slides={products}
          viewport={sliderViewport}
          centered={true}
          columnsCount={columnsCount}
        />
      </SliderWrapper>
    </div>
  );
};

const SliderWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '1300px', // define max width for related products slider on large MQs
    justifyContent: 'center',
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'center',
  marginBottom: '10px',
  marginTop: '56px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '10px',
    marginTop: '100px',
  },
}));
