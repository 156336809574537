import React from 'react';

import { Grid2 as Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import IconGrid from './icon-grid';
import { LabeledIconData } from './labeled-icon';

type IconGridProps = {
  title?: string | null;
  icons: LabeledIconData[];
  className?: string;
  narrow?: boolean;
};

export const IconGridWithTitle = ({
  title,
  icons,
  narrow,
  className,
}: IconGridProps) => {
  const titleSpacerCol = narrow ? 2 : 1;
  const iconsCol = narrow ? 6 : 9;

  return (
    <Container className={className}>
      <TitleGrid size={{ md: 2 }}>{title && <Claim>{title}</Claim>}</TitleGrid>
      <GridSpacer size={{ md: titleSpacerCol }} />
      <Grid
        size={{
          xs: 12,
          md: iconsCol,
        }}
      >
        <IconGridContainer narrow={!!narrow}>
          <StyledIconGrid icons={icons} narrow={narrow} />
        </IconGridContainer>
      </Grid>
    </Container>
  );
};

export default IconGridWithTitle;

const Container = styled(ResponsiveGrid)({
  marginLeft: 0,
  marginRight: 'auto',
});

const IconGridContainer = styled('div')(({ narrow }: { narrow: boolean }) => ({
  maxWidth: narrow ? '700px' : '1120px',
}));

const TitleGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Claim = styled('h2')(({ theme }) => ({
  ...theme.typography.h6,
  margin: 0,
}));

const StyledIconGrid = styled(IconGrid)({
  marginTop: '-42px',
});

const GridSpacer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
