import React from 'react';

type AnimationState = {
  animationFrameId: number | null;

  // position
  targetX: number;
  startX: number;
  curX: number;

  // time
  startTime: number | null;
  endTime: number | null;
};

const ANIMATION_DURATION_MS = 600;

/**
 * Utility class to animate the scrollX position of a given container.
 */
export class ScrollAnimator {
  private readonly state: AnimationState;
  private readonly targetNode: React.RefObject<HTMLDivElement>;

  constructor(targetNodeRef: React.RefObject<HTMLDivElement>) {
    this.targetNode = targetNodeRef;
    this.state = {
      animationFrameId: null,

      targetX: 0,
      startX: 0,
      curX: 0,

      startTime: null,
      endTime: null,
    };
  }

  /**
   * Cancel the animation if any is currently running.
   */
  public cancel() {
    if (this.state.animationFrameId !== null) {
      cancelAnimationFrame(this.state.animationFrameId);
    }
  }

  /**
   * Setup the animation parameters and trigger the animation.
   */
  public animateTo(targetX: number) {
    const s = this.state;

    // Resetting start and end time to `null` will allow the update loop to
    // automatically set those in the first iteration.
    s.endTime = null;
    s.startTime = null;
    s.targetX = targetX;

    if (s.animationFrameId === null) {
      s.curX = this.targetNode.current?.scrollLeft || 0;
      s.animationFrameId = requestAnimationFrame(this.update);
    }

    s.startX = s.curX;
  }

  /**
   * Advance the animation based on the given `timestamp`. This function will
   * automatically schedule another animation frame until the animation is
   * complete.
   */
  update = (timestamp: DOMHighResTimeStamp) => {
    if (this.targetNode.current) {
      const s = this.state;

      if (s.startTime === null) {
        s.startTime = timestamp;
      }

      if (s.endTime === null) {
        s.endTime = s.startTime + ANIMATION_DURATION_MS;
      }

      // The animation time is a value between 0 and 1. Technically it can be
      // bigger than one, but we only consider values between 0 and 1.
      const t = (timestamp - s.startTime) / (s.endTime - s.startTime);

      if (t >= 1) {
        s.curX = s.targetX;
        this.targetNode.current.scrollLeft = s.curX;
        s.animationFrameId = null;
      } else {
        s.curX = s.startX + (s.targetX - s.startX) * easeInOutQuad(t);
        this.targetNode.current.scrollLeft = s.curX;
        s.animationFrameId = requestAnimationFrame(this.update);
      }
    }
  };
}

export default ScrollAnimator;

const easeInOutQuad = (x: number): number =>
  x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
