import React from 'react';

import {
  Breadcrumbs,
  breadcrumbsClasses,
  Grid2 as Grid,
  Link,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ArrowRightSmallIcon } from '../../../assets/icons';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { ResponsiveGrid } from '../../layout/grid';
import { Line } from '../lines/line';

export type BreadCrumbsProps = {
  LinkComponent: React.ElementType;
  data: BreadCrumbData[];
  mobileData?: BreadCrumbData;
  localeBaseUrl: string;
  showSameDataOnMobile?: boolean;
  className?: string;
};

export type BreadCrumbData = {
  name: string;
  slug: string;
};

export const BreadCrumbs = ({
  data,
  mobileData,
  LinkComponent,
  localeBaseUrl,
  showSameDataOnMobile = false,
  className = '',
}: React.PropsWithChildren<BreadCrumbsProps>) => {
  const theme = useTheme();
  const isLgScreen = useMediaQuery(useTheme().breakpoints.up('lg'));

  const jsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: data.map((linkData, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': linkData.slug ? `${localeBaseUrl}${linkData.slug}` : undefined,
          name: linkData.name,
        },
      };
    }),
  };

  return (
    <ResponsiveGrid className={className}>
      <Grid size={12}>
        <Line sx={{ display: ['none', 'block'] }} />

        {mobileData !== undefined && (
          <MobileBreadCrumb aria-label="Breadcrumb">
            <Link
              underline="none"
              color="secondary"
              component={LinkComponent}
              to={mobileData.slug}
              sx={{
                ...theme.typography.copySm,
                fontWeight: theme.typography.fontWeightMedium,
              }}
            >
              <ArrowLeftIcon
                color="var(--color-black)"
                height={'7px'}
                width={'7px'}
                style={{ marginRight: '12px' }}
              />

              {mobileData.name}
            </Link>
          </MobileBreadCrumb>
        )}

        <BreadcrumbsStyled
          sx={{
            marginTop: {
              xs: '5px',
              sm: '9px',
            },
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.only('xs')]: {
              display: !showSameDataOnMobile && 'none',
            },
          }}
          separator={
            <ArrowRightSmallIcon
              color="var(--color-black)"
              height={isLgScreen ? '48px' : '32px'}
              width={isLgScreen ? '48px' : '32px'}
              style={{ marginTop: '3px' }}
            />
          }
          aria-label="Breadcrumb"
        >
          {data.map((linkData) =>
            linkData.slug ? (
              <Link
                key={linkData.name}
                underline="none"
                color={'secondary'}
                component={LinkComponent}
                to={linkData.slug}
                sx={{ ...theme.typography.copySm }}
              >
                {linkData.name}
              </Link>
            ) : (
              <span
                key={linkData.name}
                style={{
                  color: 'var(--color-dark-coal)',
                  ...theme.typography.copySm,
                  fontWeight: theme.typography.fontWeightMedium,
                }}
              >
                {linkData.name}
              </span>
            ),
          )}
        </BreadcrumbsStyled>
      </Grid>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonLd, null, 2),
        }}
      />
    </ResponsiveGrid>
  );
};

const MobileBreadCrumb = styled('ol')(({ theme }) => ({
  ...theme.typography.copySm,
  display: 'none',
  marginTop: '18px',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 0,

  [theme.breakpoints.only('xs')]: {
    display: 'flex',
  },
}));

const BreadcrumbsStyled = styled(Breadcrumbs)({
  [`& .${breadcrumbsClasses.separator}`]: {
    marginLeft: '0',
    marginRight: '0',
  },
});

export default BreadCrumbs;
