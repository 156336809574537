import React from 'react';

import 'swiper/css';
import 'swiper/css/bundle';

import { Card } from '@mui/material';

import {
  ContentCardSlide,
  ContentSlideData,
} from '../cards/content-card-slide';
import { FixedColumnSlider } from '../core/fixed-column-slider';
import { useSliderViewport } from '../core/viewport';

export type ContentSliderProps = React.ComponentProps<typeof Card> & {
  title?: string;
  subtitle?: string;
  slides?: ContentSlideData[];
};

export const ContentSlider = ({ title, slides = [] }: ContentSliderProps) => {
  const renderSlide = (s: ContentSlideData, idx: number) => (
    <ContentCardSlide
      key={idx}
      title={s.title}
      subtitle={s.subtitle}
      content={s.content}
      linkUrl={s.linkUrl}
      linkText={s.linkText}
      linkIcon={s.linkIcon}
      linkPiwikEventName={s.linkPiwikEventName}
    />
  );

  const viewport = useSliderViewport();

  return (
    <FixedColumnSlider
      headingComponent="h2"
      title={title}
      slides={slides}
      viewport={viewport}
      renderSlide={renderSlide}
      withCardSpacing={false}
    />
  );
};

ContentSlider.displayName = 'ContentSlider';
