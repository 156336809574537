import { Divider, Grid2 as Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import { ClaimWithContentDivider } from './base';

enum ClaimOffset {
  none = '0',
  introText = '10px',
  default = '15px',
}

export const ClaimWithContent = ({
  claimPrefix,
  claim,
  claimOffsetForIntroText = false,
  title,
  sections,
  showFinalDivider = true,
}: {
  claimPrefix?: string | null;
  claim: string;
  claimOffsetForIntroText?: boolean;
  title?: string | null;
  sections: React.ReactElement;
  showFinalDivider?: boolean;
}) => (
  <Wrapper showFinalDivider={showFinalDivider}>
    <Grid size={12}>
      <MobileDivider />
    </Grid>
    <Grid
      size={{
        xs: 12,
        md: 3,
      }}
    >
      <Claim
        claimOffset={
          title
            ? ClaimOffset.default
            : claimOffsetForIntroText
              ? ClaimOffset.introText
              : ClaimOffset.none
        }
      >
        {claimPrefix && <ClaimPrefix>{claimPrefix}</ClaimPrefix>}
        {claim}
      </Claim>
    </Grid>
    <Grid
      size={{
        xs: 12,
        md: 7,
      }}
    >
      {title && (
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 7 }}
          sx={{ marginBottom: { xs: '30px', md: '35px' } }}
        >
          <Grid
            size={{
              xs: 12,
              md: 1,
            }}
          />
          <Grid
            size={{
              xs: 11,
              sm: 9,
              md: 6,
            }}
          >
            <Title>{title}</Title>
          </Grid>
        </Grid>
      )}
      <div>{sections}</div>
      {showFinalDivider && <ClaimWithContentDivider />}
    </Grid>
  </Wrapper>
);

const MobileDivider = styled(Divider)(({ theme }) => ({
  marginTop: 0,
  marginBottom: '15px',

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const Wrapper = styled(ResponsiveGrid, {
  shouldForwardProp: (prop) => prop !== 'showFinalDivider',
})(({ showFinalDivider }: { showFinalDivider: boolean }) => ({ theme }) => ({
  marginBottom: 0,
  marginTop: '32px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: showFinalDivider ? '60px' : '80px',
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: showFinalDivider ? '80px' : '160px',
    marginTop: '100px',
  },
}));

const ClaimPrefix = styled('span')(({ theme }) => ({
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: '9px',

  [theme.breakpoints.up('sm')]: {
    marginRight: '12px',
  },
}));

const Claim = styled('p', {
  shouldForwardProp: (props) => props !== 'claimOffset',
})(({ claimOffset }: { claimOffset: ClaimOffset }) => ({ theme }) => ({
  ...theme.typography.h6,
  margin: '0 0 20px',

  [theme.breakpoints.up('md')]: {
    margin: `${claimOffset} 0 0`,
  },
}));

const Title = styled('h2')(({ theme }) => ({
  ...theme.typography.h4,
  marginTop: 0,
  marginBottom: 0,
}));
