import { DataLayer } from '@piwikpro/react-piwik-pro';
import React, { useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link as GatsbyLink } from 'gatsby';
import SwiperClass, { EffectFade } from 'swiper';

import { Grid2 as Grid, Link, LinkProps, styled } from '@mui/material';

import { Language, Market } from '@mewa/types';
import {
  ConversionButton,
  ExternalLinkIcon,
  LoginIcon,
  MobileMenuBackIcon,
  MobileSubmenuIcon,
  ResponsiveGrid,
} from '@mewa/ui';

import { getLocalizedMandatoryLink } from '../../../../libs/config/markets-utils';
import t from '../../../../libs/translate';
import { useConversionButtonLabel } from '../../../a-b-testing/cta-label-experiment';
import {
  HeaderAndFooterTranslations,
  LinkData,
  NavigationData,
} from '../types';
import classes from './menu.module.scss';

type Props = {
  consultation: { onClick: () => void; slug: string };
  langPrefix: string;
  translations: HeaderAndFooterTranslations;
  companyLinks: LinkData[];
  clothingLinks?: LinkData[];
  nonClothingLinks?: LinkData[];
  industryLinks?: LinkData[];
  navigationLinks: NavigationData['navigationLinks'];
  market: Market;
  lang: Language;
  featureDev?: boolean;
};

type ProductNavigationKind = 'clothing' | 'non-clothing' | 'industries';

enum MENU_SLIDES {
  ROOT,
  PRODUCT_INDUSTRY_OVERVIEW,
  PRODUCT_INDUSTRY_LINKS,
  COMPANY,
}

const MobileMenu = ({
  consultation,
  langPrefix,
  companyLinks = [],
  clothingLinks,
  nonClothingLinks,
  industryLinks,
  translations,
  navigationLinks,
  market,
  lang,
  featureDev,
}: Props) => {
  const [navSwiper, setNavSwiper] = useState<SwiperClass | null>(null);
  const { ctaLabel } = useConversionButtonLabel({ translations });

  const [productNavigationKind, setProductNavigationKind] =
    useState<ProductNavigationKind>('clothing');

  const onBack = useCallback(() => {
    navSwiper?.slidePrev();
  }, [navSwiper]);

  const labelBack = t(translations, 'general_form_button_label_back');

  const {
    navigation: { productMenu, company, nonClothing },
    fullService,
  } = navigationLinks;

  const companyOverViewText = t(
    translations,
    'mobile_nav_text_company_overview',
  );

  const mobileCompanyLinks: LinkData[] = [
    {
      name: companyOverViewText,
      slug: company.slug,
      isOverview: true,
    },
    ...companyLinks,
  ];

  const showCategoryPages = market === Market.DE;

  // Adding the category page links to the beginning of each category
  const mobileClothingLinks: LinkData[] = showCategoryPages
    ? [
        {
          name: t(translations, 'mobile_nav_text_clothing_overview'),
          slug: navigationLinks.navigation.clothing.slug,
          isOverview: true,
        },
        ...(clothingLinks ?? []),
      ]
    : (clothingLinks ?? []);

  const mobileNonClothingLinks: LinkData[] = showCategoryPages
    ? [
        {
          name: t(translations, 'mobile_nav_text_nonclothing_overview'),
          slug: navigationLinks.navigation.nonClothing.slug,
          isOverview: true,
        },
        ...(nonClothingLinks ?? []),
      ]
    : (nonClothingLinks ?? []);

  const mobileIndustryLinks: LinkData[] = showCategoryPages
    ? [
        {
          name: t(translations, 'mobile_nav_text_industries_overview'),
          slug: navigationLinks.navigation.industries.slug,
          isOverview: true,
        },
        ...(industryLinks ?? []),
      ]
    : (industryLinks ?? []);

  const trackCustomerPortal = () => {
    DataLayer.push({
      event: 'external_customerportal_header',
    });
  };

  return (
    <Swiper
      slidesPerView={1}
      allowTouchMove={false}
      onSwiper={setNavSwiper}
      modules={[EffectFade]}
      effect="fade"
      className={classes.menuContainer}
    >
      <SwiperSlide className={classes.menuSlide}>
        <ResponsiveGrid>
          <Grid size={12}>
            <LinkList>
              <LinkItem>
                <MenuLink
                  onClick={() => {
                    navSwiper?.slideTo(MENU_SLIDES.PRODUCT_INDUSTRY_OVERVIEW);
                  }}
                >
                  {productMenu.name}
                </MenuLink>
              </LinkItem>
              <LinkItem>
                <NavigationLink to={`${langPrefix}/${fullService.slug}`}>
                  {fullService.name}
                </NavigationLink>
              </LinkItem>
              <LinkItem>
                <MenuLink
                  onClick={() => {
                    navSwiper?.slideTo(MENU_SLIDES.COMPANY);
                  }}
                >
                  {company.name}
                </MenuLink>
              </LinkItem>
            </LinkList>

            <LinkList>
              <LinkItem sx={{ marginTop: '30px' }}>
                <ExternalLink
                  withoutIcon
                  href={getLocalizedMandatoryLink(
                    'customerPortal',
                    market,
                    lang,
                  )}
                  onClick={trackCustomerPortal}
                  sx={{ display: 'inline-flex' }}
                >
                  <LoginIcon style={{ marginRight: '14px' }} />
                  {t(translations, 'general_login')}
                </ExternalLink>
              </LinkItem>
              <LinkItem>
                <ConversionButton
                  sx={{
                    marginTop: 0,
                    display: { sm: 'inline-block' },
                    marginRight: { md: 2 },
                  }}
                  link={{
                    component: GatsbyLink,
                    props: { to: consultation.slug },
                  }}
                  onClick={consultation.onClick}
                  variant="contained"
                >
                  {ctaLabel}
                </ConversionButton>
              </LinkItem>
            </LinkList>
          </Grid>
        </ResponsiveGrid>
      </SwiperSlide>
      <SwiperSlide className={classes.menuSlide}>
        <ProductMenuSlide
          langPrefix={langPrefix}
          onBack={onBack}
          labelBack={labelBack}
          onSelect={(kind) => {
            setProductNavigationKind(kind);
            navSwiper?.slideTo(MENU_SLIDES.PRODUCT_INDUSTRY_LINKS);
          }}
          hasClothing={(clothingLinks?.length || 0) > 0}
          hasNonClothing={(nonClothingLinks?.length || 0) > 0}
          hasIndustries={(industryLinks?.length || 0) > 0}
          productOverviewSlug={productMenu.slug}
          translations={translations}
          nonClothingLink={nonClothing}
        />
      </SwiperSlide>
      <SwiperSlide className={classes.menuSlide}>
        {productNavigationKind === 'clothing' && (
          <LinkSlide
            langPrefix={langPrefix}
            links={mobileClothingLinks}
            onBack={onBack}
            labelBack={labelBack}
          />
        )}
        {productNavigationKind === 'non-clothing' && (
          <LinkSlide
            langPrefix={langPrefix}
            links={mobileNonClothingLinks}
            onBack={onBack}
            labelBack={labelBack}
          />
        )}
        {productNavigationKind === 'industries' && (
          <LinkSlide
            langPrefix={langPrefix}
            links={mobileIndustryLinks}
            onBack={onBack}
            labelBack={labelBack}
          />
        )}
      </SwiperSlide>
      <SwiperSlide className={classes.menuSlide}>
        <LinkSlide
          langPrefix={langPrefix}
          links={mobileCompanyLinks}
          onBack={() => navSwiper?.slideTo(MENU_SLIDES.ROOT)}
          labelBack={labelBack}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default MobileMenu;

const ProductMenuSlide = ({
  langPrefix,
  onSelect,
  onBack,
  hasClothing,
  hasNonClothing,
  hasIndustries,
  productOverviewSlug,
  translations,
  nonClothingLink,
  labelBack,
}: {
  langPrefix: string;
  onSelect: (kind: ProductNavigationKind) => void;
  onBack: () => void;
  hasClothing: boolean;
  hasNonClothing: boolean;
  hasIndustries: boolean;
  productOverviewSlug: string;
  translations: HeaderAndFooterTranslations;
  nonClothingLink: LinkData;
  labelBack: string;
}) => (
  <ResponsiveGrid>
    <Grid size={12}>
      <LinkList>
        <LinkItem>
          <MenuBack onClick={() => onBack()}>{labelBack}</MenuBack>
        </LinkItem>
        <LinkItem>
          <NavigationLink
            to={`${langPrefix}/${productOverviewSlug}`}
            isOverview
          >
            {t(translations, 'general_all_products_and_industries')}
          </NavigationLink>
        </LinkItem>
        {hasClothing && (
          <LinkItem>
            <MenuLink onClick={() => onSelect('clothing')}>
              {t(translations, 'navigation_clothing_label')}
            </MenuLink>
          </LinkItem>
        )}
        {hasNonClothing && (
          <LinkItem>
            <MenuLink onClick={() => onSelect('non-clothing')}>
              {nonClothingLink.name}
            </MenuLink>
          </LinkItem>
        )}
        {hasIndustries && (
          <LinkItem>
            <MenuLink onClick={() => onSelect('industries')}>
              {t(translations, 'general_industries')}
            </MenuLink>
          </LinkItem>
        )}
      </LinkList>
    </Grid>
  </ResponsiveGrid>
);

const LinkSlide = ({
  onBack,
  labelBack,
  links,
  langPrefix,
}: {
  onBack: () => void;
  labelBack: string;
  links: LinkData[];
  langPrefix: string;
}) => (
  <ResponsiveGrid>
    <Grid size={12}>
      <LinkList>
        <LinkItem>
          <MenuBack onClick={() => onBack()}>{labelBack}</MenuBack>
        </LinkItem>
        {links.map((l) => (
          <LinkItem key={l.slug}>
            {l.isExternal ? (
              <ExternalLink href={l.slug}>{l.name}</ExternalLink>
            ) : (
              <NavigationLink
                to={`${langPrefix}/${l.slug}`}
                isOverview={l.isOverview}
              >
                {l.name}
              </NavigationLink>
            )}
          </LinkItem>
        ))}
      </LinkList>
    </Grid>
  </ResponsiveGrid>
);

const ExternalLink = ({
  children,
  withoutIcon = false,
  ...props
}: React.PropsWithChildren<LinkProps & { withoutIcon?: boolean }>) => (
  <StyledLink
    {...props}
    target="_blank"
    rel="noreferrer"
    underline="none"
    color="secondary"
  >
    {children}
    {!withoutIcon ? <ExternalLinkIcon style={{ marginLeft: '5px' }} /> : null}
  </StyledLink>
);

// For navigation links to dedicated pages
const NavigationLink = ({
  children,
  to,
  isOverview = false,
}: React.PropsWithChildren<{ to: string; isOverview?: boolean }>) => (
  <StyledLink
    component={GatsbyLink}
    to={to}
    underline="none"
    color="secondary"
    className={isOverview ? 'overview-link' : ''}
  >
    {children}
  </StyledLink>
);

// For links to nested menus
const MenuLink = ({
  children,
  ...props
}: React.PropsWithChildren<LinkProps>) => (
  <MenuLinkContainer {...props} underline="none" color="secondary">
    {children}
    <MobileSubmenuIcon style={{ marginRight: '10px' }} />
  </MenuLinkContainer>
);

const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.copySm,
  paddingTop: '10px',
  paddingBottom: '10px',
  display: 'block',
  '&.overview-link': {
    fontWeight: theme.typography.fontWeightMedium,
  },
})) as typeof Link;

const MenuLinkContainer = styled(StyledLink)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}) as typeof Link;

const MenuBack = ({
  children,
  ...props
}: React.PropsWithChildren<LinkProps>) => (
  <StyledBackButton {...props} underline="none" color="secondary">
    <MobileMenuBackIcon style={{ marginRight: '7px' }} />
    {children}
  </StyledBackButton>
);

const LinkList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

const LinkItem = styled('li')({
  marginTop: '4px',
  marginBottom: '4px',
});

const StyledBackButton = styled(Link)(({ theme }) => ({
  ...theme.typography.copySm,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '6px',
  padding: '10px 0 10px',
}));
