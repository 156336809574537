/**
 * Register scroll & resize handler and set the appropriate class names on the
 * `node` based on the current scroll position.
 */
export const registerScrollState = (node: HTMLElement): ScrollState => {
  const state = new ScrollState(node);
  state.register();
  return state;
};

export class ScrollState {
  private readonly targetNode: HTMLElement;
  private scrollY: number;
  private readonly threshold: number;

  constructor(targetNode: HTMLElement, scrollY = 0, threshold = 100) {
    this.targetNode = targetNode;
    this.scrollY = scrollY;
    this.threshold = threshold;
  }

  register = () => {
    window.addEventListener('scroll', this.handleStateUpdate);
    window.addEventListener('resize', this.handleStateUpdate);

    this.scrollY = window.scrollY;
  };

  unregister = () => {
    window.removeEventListener('scroll', this.handleStateUpdate);
    window.removeEventListener('resize', this.handleStateUpdate);
  };

  handleStateUpdate = () => {
    // Some browsers (e.g. Safari) might fire scroll events when the user
    // scrolls past the actual document size (bounce effect). This will mess up
    // our state because we think the user scrolls up/down but in reality the
    // browser just "bounces" back to the correct position.
    //
    // So we check if the scroll is "out of bounds" and ignore those events.
    const sy = window.scrollY;

    if (sy >= 0 && sy + window.innerHeight < document.body.clientHeight) {
      this.updateScrollState(sy);
    }
  };

  updateScrollState = (newScrollY: number) => {
    const showDividerPrevious = this.scrollY > this.threshold;
    const showDivider = newScrollY > this.threshold;

    if (showDividerPrevious !== showDivider) {
      this.renderDividerState(showDivider);
    }

    this.scrollY = newScrollY;
  };

  renderDividerState = (showDivider: boolean) => {
    const className = `scroll-state-greater-threshold`;

    if (showDivider) {
      this.targetNode.classList.add(className);
    } else {
      this.targetNode.classList.remove(className);
    }
  };
}
