import React, { useCallback, useEffect, useState } from 'react';

import { styled } from '@mui/material';

import { Controls } from './controls';
import { SlideData } from './types';

type AnimatedImagesProps = {
  slides: SlideData[];
};

const randomItem = <T extends NonNullable<unknown>>(array: T[]): T => {
  return array[Math.floor(Math.random() * array.length)];
};

const availableClassNames = [
  'col-1-row-12',
  'col-2-row-12',
  'col-1-row-1',
  'col-1-row-2',
  'col-2-row-1',
  'col-2-row-2',
];

export const TileImages = ({ slides }: AnimatedImagesProps) => {
  const [classes, setClasses] = useState(
    slides.map((item, index) => {
      return index === 0
        ? { ...item, className: 'col-1-row-12' }
        : index === 1
          ? slides.length > 2
            ? { ...item, className: 'col-2-row-1' }
            : { ...item, className: 'col-2-row-12' }
          : index === 2
            ? { ...item, className: 'col-2-row-2' }
            : { ...item, className: 'is-hidden' };
    }),
  );

  const [isPaused, setIsPaused] = useState<boolean>(false);

  const [previousClassName, setPreviousClassName] =
    useState<string>('col-1-row-12');

  const playAnimation = useCallback(() => {
    if (slides.length > 2) {
      const int = setInterval(() => {
        // get items without class
        const freeItem = classes.filter(
          (item) => item.className === 'is-hidden',
        );
        // get random class name
        let newClass = randomItem(availableClassNames);
        while (newClass === previousClassName) {
          newClass = randomItem(availableClassNames);
        }
        const newColumn = newClass.slice(0, 5);

        // select free item that is not yet visible
        const nextItem = randomItem(freeItem);

        const indexOfNextItem = classes.findIndex(
          (slide) => slide.src === nextItem.src,
        );

        setClasses((old) => {
          const newClasses = old.map((item, index, all) => {
            // check for new class which other items get class removed
            if (
              newClass === 'col-1-row-12' &&
              item.className.includes('col-1')
            ) {
              item.className = 'is-hidden';
            } else if (
              newClass === 'col-2-row-12' &&
              item.className.includes('col-2')
            ) {
              item.className = 'is-hidden';
            } else if (item.className === newClass) {
              item.className = 'is-hidden';
            }
            return item;
          });

          // if both rows in a column are filled with separate images, remove the classname of the item
          // that is covering two rows of this column.
          if (
            (newClass.includes('row-1') &&
              newClasses.find((i) => i.className === `${newColumn}-row-2`)) ||
            (newClass.includes('row-2') &&
              newClasses.find((i) => i.className === `${newColumn}-row-1`))
          ) {
            const coveredColumn = newClasses.find(
              (i) => i.className === `${newColumn}-row-12`,
            );
            if (coveredColumn) {
              coveredColumn.className = 'is-hidden';
            }
          }

          // set class for random item after all other classes are checked
          newClasses[indexOfNextItem] = {
            ...nextItem,
            className: newClass,
          };
          setPreviousClassName(newClass);
          return newClasses;
        });
        clearInterval(int);
      }, 1000);
    }
  }, [classes, previousClassName, slides.length]);

  const pause = () => {
    setIsPaused(true);
  };

  useEffect(() => {
    if (!isPaused) {
      playAnimation();
    }
  }, [isPaused, playAnimation]);

  const play = () => {
    setIsPaused(false);
  };

  return (
    <Wrapper>
      <TileWrapper>
        {classes.map((item, index) => (
          <Image key={index} className={item.className}>
            {slides[index].image}
          </Image>
        ))}
      </TileWrapper>
      <AnimationControls>
        <Controls isPaused={isPaused} pause={pause} play={play} />
      </AnimationControls>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));

const TileWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  position: 'relative',

  aspectRatio: '3 / 2',
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    aspectRatio: '1.5 / 2',
  },
}));

const AnimationControls = styled('div')(({ theme }) => ({
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  left: '50%',
}));

const Image = styled('div')(({ theme }) => ({
  position: 'relative',

  '> img, > .gatsby-image-wrapper': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  '&.col-1-row-12': {
    gridColumn: '1',
    gridRow: '1 / span 2',
  },

  '&.col-2-row-12': {
    gridColumn: '2',
    gridRow: '1 / span 2',

    [theme.breakpoints.down('sm')]: {
      gridColumn: '1',
    },
  },

  '&.col-2-row-1': {
    gridColumn: '2',
    gridRow: '1',
    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      gridColumn: '1',
    },
  },

  '&.col-2-row-2': {
    gridColumn: '2',
    gridRow: '2',
    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      gridColumn: '1',
    },
  },

  '&.col-1-row-1': {
    gridColumn: '1',
    gridRow: '1',
    zIndex: 2,
  },

  '&.col-1-row-2': {
    gridColumn: '1',
    gridRow: '2',
    zIndex: 2,
  },

  '&.is-hidden': {
    opacity: 0,
    maxHeight: 0,
  },
}));
