import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { cssVars, useWindowResize } from '../../../index';

export const useSectionsHeightAnimation = (
  duration: number,
  activeIndex: number,
  container: RefObject<HTMLDivElement>,
) => {
  const [sectionToFadeIn, setFadeInSection] = useState(activeIndex);
  const [sectionToFadeOut, setFadeOutSection] = useState(-1);

  const heightAutoRequest = useRef<number | null>(null);

  const cancelHeightAutoRequest = useCallback(() => {
    if (heightAutoRequest.current) {
      cancelAnimationFrame(heightAutoRequest.current);
      heightAutoRequest.current = null;
    }
    // set container to height auto immediately
    if (container.current) {
      container.current.style.height = `auto`;
    }
  }, [container, heightAutoRequest]);

  // fade in the new section after half the duration
  const fadeIn = useCallback(
    (fadeInSection: number) => {
      setTimeoutBrowserFriendly(() => {
        setFadeInSection(fadeInSection);
      }, duration / 2);
    },
    [setFadeInSection, duration],
  );

  // get the current height and the new height (with the new section to fade in)
  const calculateContainerHeight = (
    fadeInIndex: number,
    fadeOutIndex: number,
    containerElem: HTMLDivElement,
  ) => {
    const sections = containerElem.childNodes as NodeListOf<HTMLElement>;

    containerElem.style.height = `auto`;
    const currentHeight = containerElem.getBoundingClientRect().height;

    // render new section oto geht the new height
    sections.item(fadeInIndex).style.height = 'auto';
    sections.item(fadeOutIndex).style.height = '0';
    const newHeight = containerElem.getBoundingClientRect().height;

    // restore the current section
    sections.item(fadeInIndex).style.height = '0';
    sections.item(fadeOutIndex).style.height = 'auto';

    return { currentHeight, newHeight };
  };

  // animate the container to the new section height
  const animateHeight = useCallback(
    (fadeInIndex: number, fadeOutIndex: number) => {
      cancelHeightAutoRequest();

      if (container.current) {
        const containerElem = container.current;
        const { currentHeight, newHeight } = calculateContainerHeight(
          fadeInIndex,
          fadeOutIndex,
          containerElem,
        );

        scrollIntoContainerTop(containerElem);

        // set current height in px as the start value for the transitions
        containerElem.style.height = `${currentHeight}px`;

        requestAnimationFrame(() => {
          // set new height in px to start the height transition
          containerElem.style.height = `${newHeight}px`;

          setTimeoutBrowserFriendly(
            () => (containerElem.style.height = `auto`),
            duration,
            (id) => (heightAutoRequest.current = id),
          );
        });
      }
    },
    [cancelHeightAutoRequest, duration, container, heightAutoRequest],
  );

  // fade in and out the sections after activeIndex has changed
  useEffect(() => {
    if (sectionToFadeOut === -1 && activeIndex === sectionToFadeIn) {
      // initialize active section without fading
      setFadeOutSection(-1);
      setFadeInSection(activeIndex);
    } else if (
      sectionToFadeOut !== sectionToFadeIn &&
      activeIndex !== sectionToFadeIn
    ) {
      setFadeOutSection(sectionToFadeIn);
      fadeIn(activeIndex);
      animateHeight(activeIndex, sectionToFadeIn);
    }
  }, [activeIndex, animateHeight, sectionToFadeOut, sectionToFadeIn, fadeIn]);

  const initializeDomElements = useCallback(() => {
    if (container.current) {
      container.current.style.transition = `height ${duration}ms ease`;

      const sections = container.current.childNodes as NodeListOf<HTMLElement>;
      sections.forEach((section) => {
        section.style.height = '0';
        section.style.opacity = '0';
        section.style.transition = `opacity ${duration / 2}ms ease`;
      });
    }
  }, [container, duration]);

  // initialize container and sections with its styles
  useEffect(() => {
    initializeDomElements();

    return () => {
      cancelHeightAutoRequest();
    };
  }, [cancelHeightAutoRequest, initializeDomElements]);

  // fade out previous section
  useEffect(() => {
    if (sectionToFadeOut !== -1 && container.current) {
      (
        container.current.childNodes.item(sectionToFadeOut) as HTMLElement
      ).style.opacity = '0';
    }
  }, [sectionToFadeOut, container]);

  const updateSectionsOpacity = useCallback(() => {
    if (container.current) {
      (container.current.childNodes as NodeListOf<HTMLElement>).forEach(
        (tab, index) => {
          if (index === sectionToFadeIn) {
            tab.style.opacity = '1';
            tab.style.height = 'auto';
          } else {
            tab.style.opacity = '0';
            tab.style.height = '0';
          }
        },
      );
    }
  }, [sectionToFadeIn, container]);

  // fade in active section
  useEffect(() => {
    updateSectionsOpacity();
  }, [updateSectionsOpacity]);

  const resizeHandler = useCallback(() => {
    initializeDomElements();
    requestAnimationFrame(() => {
      updateSectionsOpacity();
    });
  }, [initializeDomElements, updateSectionsOpacity]);

  useWindowResize(resizeHandler);
};

const setTimeoutBrowserFriendly = (
  callback: () => void,
  durationInMs: number,
  setId?: (id: number | null) => void,
) => {
  const start = Date.now();

  const action = () => {
    let requestId = null;
    if (Date.now() - start >= durationInMs) {
      callback();
    } else {
      requestId = requestAnimationFrame(action);
    }
    if (setId) {
      setId(requestId);
    }
  };

  const requestId = requestAnimationFrame(action);
  if (setId) {
    setId(requestId);
  }
};

const scrollIntoContainerTop = (container: HTMLElement) => {
  const headerOffset = cssVars.headerHeightDesktop;
  const elementPosition = container.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};
