import { Components, Theme, tooltipClasses } from '@mui/material';

const tooltipMargin = '10px';
const arrowHeight = '16px';

export const tooltip = (theme: Theme): Components<Theme>['MuiTooltip'] => ({
  styleOverrides: {
    arrow: {
      color: 'var(--color-white)',
      width: '32px',
      height: '32px',

      '::before': {
        width: '70%',
        height: '70%',
        margin: '15%',
      },
    },

    tooltip: {
      color: 'var(--color-dark-coal)',
      maxWidth: '340px',
      backgroundColor: 'var(--color-white)',
      filter: 'drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.30))',
      padding: '28px 10px 20px 30px',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '900px',
        margin: '0 20px 0 20px',
      },
    },

    popper: {
      [`&[data-popper-placement] .${tooltipClasses.arrow}::before`]: {
        transformOrigin: 'center center',
      },

      [`&[data-popper-placement*="top"]`]: {
        paddingBottom: tooltipMargin,

        [`.${tooltipClasses.tooltip}`]: {
          marginBottom: arrowHeight,
        },

        [`.${tooltipClasses.arrow}`]: {
          marginBottom: `-${arrowHeight}`,
        },
      },

      [`&[data-popper-placement*="right"]`]: {
        paddingLeft: tooltipMargin,

        [`.${tooltipClasses.tooltip}`]: {
          marginLeft: arrowHeight,
        },

        [`.${tooltipClasses.arrow}`]: {
          marginLeft: `-${arrowHeight}`,
        },
      },

      [`&[data-popper-placement*="bottom"]`]: {
        paddingTop: tooltipMargin,

        [`.${tooltipClasses.tooltip}`]: {
          marginTop: arrowHeight,
        },

        [`.${tooltipClasses.arrow}`]: {
          marginTop: `-${arrowHeight}`,
        },
      },

      [`&[data-popper-placement*="left"]`]: {
        paddingRight: tooltipMargin,

        [`.${tooltipClasses.tooltip}`]: {
          marginRight: arrowHeight,
        },

        [`.${tooltipClasses.arrow}`]: {
          marginRight: `-${arrowHeight}`,
        },
      },
    },
  },
});
