import { useEffect, useRef, useState } from 'react';

type FadeLabelProps = {
  text: string;
  className?: string;
  component?: React.ElementType;
  onLabelSizeChange?: (newWidth: number, newHeight: number) => void;
  fadeInDelayMs?: number;
};

export const FadeText = ({
  text,
  className,
  component,
  onLabelSizeChange,
  fadeInDelayMs,
}: FadeLabelProps) => {
  const [displayText, setDisplayText] = useState(text);
  const [show, setShow] = useState(true);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isMounted = useRef(false);
  const elementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    setShow(false);

    timeoutId.current = setTimeout(() => {
      setDisplayText(text);
      setShow(true);
    }, 300);

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [text, setDisplayText, setShow]);

  useEffect(() => {
    if (show && onLabelSizeChange && elementRef.current) {
      const bBox = elementRef.current.getBoundingClientRect();
      onLabelSizeChange(bBox.width, bBox.height);
    }
  }, [onLabelSizeChange, show]);

  const Component = component || 'p';

  return (
    <Component
      ref={elementRef}
      style={{
        opacity: show ? 1 : 0,
        transition:
          'opacity var(--transition-duration) var(--transition-timing)',
        transitionDelay: show ? `${fadeInDelayMs || 0}ms` : '0s',
      }}
      className={className}
    >
      {displayText}
    </Component>
  );
};

export default FadeText;
