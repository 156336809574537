import React, { memo } from 'react';
import { useInView } from 'react-intersection-observer';

import { styled } from '@mui/material';

type SectionContentProps = {
  id: string;
  onIntersectionChange: (
    inView: boolean,
    entry: IntersectionObserverEntry,
  ) => void;
};

export const SectionContent = memo(
  ({
    id,
    children,
    onIntersectionChange,
  }: React.PropsWithChildren<SectionContentProps>) => {
    const [ref] = useInView({
      // Trigger Intersection Observer whenever image appears 10% more or less in the viewport
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      onChange: onIntersectionChange,
    });

    return (
      <ContentWrapper id={id} ref={ref}>
        {children}
      </ContentWrapper>
    );
  },
);

const ContentWrapper = styled('div')({
  ':not(:first-of-type)': {
    marginTop: '100px',
  },
});
