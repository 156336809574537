import React from 'react';

import {
  Button,
  buttonClasses,
  Grid2 as Grid,
  styled,
  SxProps,
} from '@mui/material';

import ResponsiveGrid from '../../../layout/grid';
import { LinkProps } from '../types';

export type ConsultationTeaserProps<T extends React.ElementType> = {
  image: React.ReactElement;
  headline: string;
  textblocks: {
    headline: string;
    text: string;
  }[];
  link: LinkProps<T>;
  onClick?: () => void;
  sx?: SxProps;
  linkLabel: string;
};

export const ConsultationTeaser = <T extends React.ElementType>({
  image,
  headline,
  textblocks,
  link,
  onClick,
  sx,
  linkLabel,
}: ConsultationTeaserProps<T>) => {
  return (
    <Container sx={sx}>
      <TeaserGrid size={{ xs: 12, md: 10 }} offset={{ xs: 0, md: 1 }}>
        <ImageContainer>{image}</ImageContainer>
      </TeaserGrid>

      <TeaserGrid size={{ xs: 12, md: 10 }} offset={{ xs: 0, md: 1 }}>
        <Headline>{headline}</Headline>
      </TeaserGrid>

      <TeaserGrid size={{ xs: 12, md: 10 }} offset={{ xs: 0, md: 1 }}>
        <Textblocks>
          {textblocks.map((textblock) => (
            <TextblockItem key={textblock.headline}>
              <TextblockHeadline>{textblock.headline}</TextblockHeadline>
              <TextblockText>{textblock.text}</TextblockText>
            </TextblockItem>
          ))}
        </Textblocks>
      </TeaserGrid>

      <Grid size={{ xs: 12, md: 10 }} offset={{ xs: 0, md: 1 }}>
        <ButtonStyleWrapper>
          <Button
            variant="contained"
            color="primary"
            component={link.component}
            {...link.props}
            onClick={onClick}
          >
            {linkLabel}
          </Button>
        </ButtonStyleWrapper>
      </Grid>
    </Container>
  );
};

const Container = styled(ResponsiveGrid)(({ theme }) => ({
  margin: 0,
}));

const ImageContainer = styled('div')(({ theme }) => ({
  lineHeight: 0,
  aspectRatio: '4 / 3',

  '.gatsby-image-wrapper': {
    height: '100%',
  },

  [theme.breakpoints.up('sm')]: {
    aspectRatio: '21 / 9',
  },
}));

const TeaserGrid = styled(Grid)(({ theme }) => ({
  marginBottom: '30px',
  marginTop: 0,

  [theme.breakpoints.up('sm')]: {
    marginBottom: '40px',
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '50px',
  },

  [theme.breakpoints.up('lg')]: {
    marginBottom: '60px',
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'center',
  width: '100%',
  margin: 0,
}));

const ButtonStyleWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',

  [`.${buttonClasses.contained}`]: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Textblocks = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '70px',
  },

  [theme.breakpoints.up('md')]: {
    gap: '90px',
  },

  [theme.breakpoints.up('lg')]: {
    gap: '100px',
  },
}));

const TextblockItem = styled('div')(({ theme }) => ({
  marginBottom: '20px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
    flexBasis: '33%',
  },
}));

const TextblockHeadline = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightMedium,
  margin: '0 0 10px 0',
}));

const TextblockText = styled('p')(({ theme }) => ({
  margin: 0,
}));
