import t from '../../libs/translate';

enum Label {
  general_consultation_cta = 'general_consultation_cta',
  book_consultation_button_label = 'book_consultation_button_label',
}

type Translations = Record<Label, string>;

const DEFAULT_VALUE = Label.general_consultation_cta;

export const useConversionButtonLabel = ({
  translations,
}: {
  translations: Translations;
}) => ({ ctaLabel: t(translations, DEFAULT_VALUE) });
