import { SwiperSlide, SwiperSlideProps } from 'swiper/react';

import { styled, SxProps } from '@mui/material';

export type CardSlideProps = SwiperSlideProps & {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  sx?: SxProps;
};

const CardSlide: React.FC<CardSlideProps> = ({
  style,
  className,
  children,
  sx,
}) => (
  <StyledSlide sx={sx} style={style} className={`${className}`}>
    {children}
  </StyledSlide>
);

CardSlide.displayName = 'SwiperSlide';

export default CardSlide;

const StyledSlide = styled(SwiperSlide)({
  overflow: 'visible',
  marginRight: '-1px',
  zIndex: 0,
  transition:
    'z-index var(--transition-duration) var(--transition-timing), border-color var(--transition-duration) var(--transition-timing)',
  border: '1px solid var(--color-medium-light-grey)',

  '&:hover': {
    zIndex: 1,
    borderColor: 'var(--color-light-red)',
  },
});
