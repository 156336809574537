import React from 'react';

import {
  SliderButtonNext,
  SliderButtonPause,
} from '../slider-button/slider-button';

type ControlsProps = {
  isPaused: boolean;
  play: () => void;
  pause: () => void;
};

export const Controls = ({ isPaused, play, pause }: ControlsProps) => {
  return isPaused ? (
    <SliderButtonNext
      onClick={play}
      styling={{ fillMode: 'outlined', color: 'white' }}
      hoverStyling={{ fillMode: 'outlined', color: 'light-red' }}
    ></SliderButtonNext>
  ) : (
    <SliderButtonPause
      onClick={pause}
      styling={{ fillMode: 'outlined', color: 'white' }}
      hoverStyling={{ fillMode: 'outlined', color: 'light-red' }}
    ></SliderButtonPause>
  );
};
