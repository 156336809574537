import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';

import { MarkdownContainer } from './markdown-container';

type Props = {
  html: string;
  title: string;
};

export const MarkdownAccordion = ({ html, title }: Props) => {
  return (
    <Accordion>
      <AccordionSummary>{title}</AccordionSummary>
      <AccordionDetails>
        <StyledMarkdownContainer html={html} />
      </AccordionDetails>
    </Accordion>
  );
};

const StyledMarkdownContainer = styled(MarkdownContainer)(({ theme }) => ({
  p: {
    '&:first-child': { marginTop: 0 },
    '&:last-child': { marginBottom: 0 },
  },

  marginBottom: 0,

  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
  },
}));
