import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import {
  Button,
  buttonClasses,
  ButtonTypeMap,
  Grid2 as Grid,
  styled,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import ResponsiveGrid from '../../layout/grid';

export const StageWithCta = ({
  title,
  subtitle,
  image,
  link,
  piwikEventNameCTA,
  labelCTA,
}: {
  title: string;
  subtitle: React.ReactElement | string;
  image: React.ReactElement;
  link: {
    component: React.ElementType;
    props: OverrideProps<ButtonTypeMap, React.ElementType>;
  };
  labelCTA: string;
  piwikEventNameCTA: string;
}) => (
  <Container>
    <PositionContainer>
      <ImageContainer>
        {image}
        <GradientContainer />
      </ImageContainer>
    </PositionContainer>
    <TextContainer>
      <ResponsiveGrid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <ButtonStyleWrapper>
            <Button
              variant="contained"
              color="primary"
              component={link.component}
              {...link.props}
              onClick={() =>
                DataLayer.push({
                  event: piwikEventNameCTA || '',
                })
              }
            >
              {labelCTA}
            </Button>
          </ButtonStyleWrapper>
        </Grid>
      </ResponsiveGrid>
    </TextContainer>
  </Container>
);

const TextContainer = styled('div')(({ theme }) => ({
  marginBottom: '25px',

  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '0',
    zIndex: 1,
    marginBottom: 0,
  },
}));

const PositionContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',

  [theme.breakpoints.down('sm')]: {
    aspectRatio: 375 / 250,
  },
}));

const GradientContainer = styled('div')(({ theme }) => ({
  background:
    'linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%)',
  inset: '0 30% 0 0',
  position: 'absolute',
  opacity: 0.5,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    aspectRatio: 900 / 350,
  },

  [theme.breakpoints.up('md')]: {
    aspectRatio: 1440 / 450,
  },

  [theme.breakpoints.up('lg')]: {
    aspectRatio: 1920 / 500,
  },
}));

const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  fontWeight: theme.typography.fontWeightLight,
  marginTop: '25px',
  marginBottom: '5px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '8px',
    marginTop: 0,
    color: 'var(--color-white)',
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '10px',
  },

  [theme.breakpoints.up('lg')]: {
    marginBottom: '12px',
  },
}));

const Subtitle = styled('h4')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: theme.typography.fontWeightRegular,
  marginBottom: '20px',
  marginTop: '0px',

  strong: {
    fontWeight: theme.typography.fontWeightMedium,
  },

  [theme.breakpoints.up('sm')]: {
    color: 'var(--color-white)',
    marginBottom: '30px',
  },
}));

const ImageContainer = styled('div')({
  height: '100%',

  '> .gatsby-image-wrapper': {
    height: '100%',
  },

  img: { width: '100%' },
});

const ButtonStyleWrapper = styled('div')(({ theme }) => ({
  [`.${buttonClasses.contained}`]: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '18px',
    },
  },
}));
