import { styled, SxProps } from '@mui/material';

import { TriangleUpIcon } from '../../../assets/icons';
import Caption from '../text/caption';

export type LabeledNumberData = {
  number: number;
  suffix?: string | null;
  label: string;
};

export type LabeledNumberProps = LabeledNumberData & {
  labelPosition?: 'top' | 'bottom' | null;
  size?: 'default' | 'small';
  sx?: SxProps;
  className?: string;
  locale?: string;
};

export const LabeledNumber = ({
  number,
  suffix,
  label,
  labelPosition,
  size,
  sx,
  className,
  locale,
}: LabeledNumberProps) => (
  <RootContainer
    sx={{
      flexDirection: labelPosition !== 'top' ? 'column-reverse' : 'column',
      ...sx,
    }}
    className={className}
  >
    <NumberLabel variant="small">{label}</NumberLabel>
    <NumberContainer size={size} bottomLabel={labelPosition !== 'top'}>
      {locale ? formatNumber(number, locale) : number} <Suffix>{suffix}</Suffix>
      {labelPosition === 'top' ? (
        <TriangleUpIcon
          style={{
            width: '12px',
            marginLeft: '5px',
            fill: 'var(--color-medium-light-grey)',
          }}
        />
      ) : undefined}
    </NumberContainer>
  </RootContainer>
);

export default LabeledNumber;

const formatNumber = (n: number, locale: string): string => {
  const f = new Intl.NumberFormat(locale);
  return f.format(n);
};

const RootContainer = styled('div')({
  display: 'inline-flex',
});

const NumberContainer = styled('p', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'bottomLabel',
})<{ size?: 'default' | 'small'; bottomLabel: boolean }>(
  ({ theme, size, bottomLabel }) => ({
    ...theme.typography.h1,
    fontWeight: theme.typography.fontWeightLight,
    padding: 0,
    paddingLeft: size === 'small' ? '16px' : 0,
    marginTop: bottomLabel ? 0 : '16px',
    marginBottom: bottomLabel ? '16px' : 0,
    marginLeft: '-3px',

    ...(size === 'small' && {
      ...theme.typography.h2,
    }),

    [theme.breakpoints.down('sm')]: {
      marginTop: bottomLabel ? 0 : '4px',
      marginBottom: bottomLabel ? '8px' : 0,
      marginLeft: '-6px',
    },
  }),
);

const NumberLabel = styled(Caption)({
  width: '90%',
});

const Suffix = styled('span')(({ theme }) => ({
  ...theme.typography.h5,
}));
