import t from '../../../libs/translate';
import { SearchPageTranslations } from '../../components/header/types';

export const decodeHtmlChars = (text: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

export const translateSectionLinkNumerus = (
  amount: number,
  labelSingular: keyof SearchPageTranslations,
  labelPlural: keyof SearchPageTranslations,
  searchPageTranslations: SearchPageTranslations,
) =>
  t(searchPageTranslations, amount === 1 ? labelSingular : labelPlural, {
    amount: amount.toFixed(0),
  });
