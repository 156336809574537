import { AlternativeLink } from '@mewa/types';

export type MetaProps = {
  title: string;
  description?: string;
  keywords?: string[];
  alternatives?: AlternativeLink[];
};

export enum LayoutType {
  Default = 'default',
  Minimal = 'minimal',
  Campaign = 'campaign',
}

// These properties can be set on the component rendering the page
export type LayoutFlags = {
  layout?: LayoutType;
  noContactTeaser?: boolean;
};
