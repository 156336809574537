import { styled, useMediaQuery, useTheme } from '@mui/material';

import { MarkdownContainer } from '../markdown/markdown-container';
import { MobileSlider } from '../mobile-slider/mobile-slider';

export type CostItem = {
  headline: string;
  description: {
    data: {
      childMarkdownRemark: {
        html: string;
      };
    };
  };
};

export const CostSection = ({ items }: { items: CostItem[] }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderedItems = items.map((item, i) => (
    <div key={i}>
      <Headline>{item.headline}</Headline>
      <StyledMarkdownContainer
        html={item.description.data.childMarkdownRemark.html}
      />
    </div>
  ));

  return isMobile ? (
    <MobileSlider>{renderedItems}</MobileSlider>
  ) : (
    <StyledGrid itemCount={renderedItems.length}>{renderedItems}</StyledGrid>
  );
};

export default CostSection;

const StyledGrid = styled('div')(
  ({ itemCount = 4 }: { itemCount: number }) =>
    ({ theme }) => ({
      display: 'grid',
      margin: '0 auto',
      rowGap: '50px',
      columnGap: 'var(--gutter)',
      gridTemplateColumns: `repeat(2, 1fr)`,

      [theme.breakpoints.between(1280, 1440)]: {
        gridTemplateColumns: `repeat(1, 1fr)`,
      },

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns:
          itemCount >= 4 ? 'repeat(4, 1fr)' : `repeat(${itemCount}, 1fr)`,
      },

      [theme.breakpoints.up('md')]: {
        width: '83.334%',
      },
    }),
);

const Headline = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  marginTop: 0,
  marginBottom: 0,
}));

const StyledMarkdownContainer = styled(MarkdownContainer)(({ theme }) => ({
  marginTop: '10px',

  p: {
    ...theme.typography.copy,
  },

  [theme.breakpoints.up('xs')]: {
    marginBottom: 0, // overwrite marginBottom for all media queries
  },

  'p:first-of-type': {
    marginTop: 0,
  },

  'p:last-of-type': {
    marginBottom: 0,
  },
}));
