import { useCallback, useEffect, useRef } from 'react';

type EventCallback = () => void;

export const useWindowResize = (callback: EventCallback) => {
  const oldCallback = useRef<EventCallback | null>(null);

  const resizeHandler = useCallback(() => {
    callback();
  }, [callback]);

  useEffect(() => {
    if (oldCallback.current) {
      window.removeEventListener(
        'resize',
        oldCallback.current as EventListener,
      );
    }

    window.addEventListener('resize', resizeHandler);
    oldCallback.current = resizeHandler;

    return () => {
      if (oldCallback.current) {
        window.removeEventListener(
          'resize',
          oldCallback.current as EventListener,
        );
      }
    };
  }, [oldCallback, resizeHandler]);
};

export const useDebouncedWindowResize = (
  callback: EventCallback,
  debounceDelay = 300,
) => {
  const oldCallback = useRef<EventCallback | null>(null);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resizeHandler = useCallback(() => {
    callback();
  }, [callback]);

  const debouncedResizeHandler = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(resizeHandler, debounceDelay);
  }, [resizeHandler, timeoutId, debounceDelay]);

  useEffect(() => {
    if (oldCallback.current) {
      window.removeEventListener(
        'resize',
        oldCallback.current as EventListener,
      );
    }

    window.addEventListener('resize', debouncedResizeHandler);
    oldCallback.current = debouncedResizeHandler;

    return () => {
      if (oldCallback.current) {
        window.removeEventListener(
          'resize',
          oldCallback.current as EventListener,
        );
      }
    };
  }, [oldCallback, debouncedResizeHandler]);
};
