type Props = {
  title: string;
  videoId: string;
  autoplay?: boolean;
};

export const YoutubeVideo = ({ title, videoId, autoplay }: Props) => (
  <iframe
    src={`https://www.youtube-nocookie.com/embed/${videoId}${
      autoplay ? '?autoplay=1' : ''
    }`}
    title={title}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
);

export default YoutubeVideo;
