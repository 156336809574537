import { Grid2 as Grid, styled } from '@mui/material';

import { ResponsiveGrid, StickyHeader } from '@mewa/ui';

import { HeaderWrapper, MewaLogo, wrapperStyles } from './header-base';

export const MinimalHeader = ({ langPrefix }: { langPrefix: string }) => (
  <StyledStickyHeader>
    <ResponsiveGrid
      sx={wrapperStyles}
      gridProps={{
        sx: {
          height: '100%',
        },
      }}
    >
      <Grid size={12}>
        <HeaderWrapper>
          <MewaLogo langPrefix={langPrefix} />
        </HeaderWrapper>
      </Grid>
    </ResponsiveGrid>
  </StyledStickyHeader>
);

const StyledStickyHeader = styled(StickyHeader)(({ theme }) => ({
  height: 'var(--header-height-open-menu)',
}));
