import React from 'react';

import { IconButton, iconButtonClasses, styled } from '@mui/material';

import { InfoIcon } from '../../../assets/icons';

export const InfoButton = React.forwardRef<HTMLButtonElement>((props, ref) => (
  <IconButton
    {...props}
    ref={ref}
    disableRipple={true}
    sx={{ verticalAlign: 'initial' }}
  >
    <IconWrapper>
      <InfoIcon />
    </IconWrapper>
  </IconButton>
));

const IconWrapper = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '20px',
  width: '20px',
  height: '20px',
  border: '1px solid var(--color-light-red)',
  backgroundColor: 'var(--color-white)',
  borderRadius: '10px',
  cursor: 'pointer',
  transition: 'background-color var(--transition-duration)',
  outline: 'none',

  svg: {
    color: 'var(--color-light-red)',
    transition: 'color var(--transition-duration)',
  },

  [`.${iconButtonClasses.root}:hover &, .${iconButtonClasses.root}:focus-visible &`]:
    {
      backgroundColor: 'var(--color-light-red)',
      color: 'white',
      svg: {
        color: 'var(--color-white)',
      },
    },
}));
