import { MewaCircleIcon } from '@mewa/types';

import {
  DropletIcon,
  HeadProtectionIcon,
  MagnifierIcon,
  MbkIcon,
  MbsIcon,
  MbsMtsCombinedIcon,
  MfsIcon,
  MtrChangeLiquidIcon,
  MtrCleaningIcon,
  MtrExchangeIcon,
  MtrIcon,
  MtrImmediateHelpIcon,
  MtsIcon,
  MultitexIcon,
  MwsIcon,
  PreparationDin14644Icon,
  ToptexIcon,
  TruckRightIcon,
  WipingmopsIcon,
} from '../../../assets/icons';

export const iconMap: Record<MewaCircleIcon, React.FunctionComponent> = {
  [MewaCircleIcon.CategoryMbsMtsCombined]: MbsMtsCombinedIcon,
  [MewaCircleIcon.CategoryMbs]: MbsIcon,
  [MewaCircleIcon.CategoryMts]: MtsIcon,
  [MewaCircleIcon.CategoryMbk]: MbkIcon,
  [MewaCircleIcon.CategoryMfs]: MfsIcon,
  [MewaCircleIcon.CategoryMtr]: MtrIcon,
  [MewaCircleIcon.CategoryMultitex]: MultitexIcon,
  [MewaCircleIcon.CategoryMws]: MwsIcon,
  [MewaCircleIcon.CategoryToptex]: ToptexIcon,
  [MewaCircleIcon.CategoryWipingmops]: WipingmopsIcon,
  [MewaCircleIcon.Truck]: TruckRightIcon,
  [MewaCircleIcon.Magnifier]: MagnifierIcon,
  [MewaCircleIcon.Droplet]: DropletIcon,
  [MewaCircleIcon.MtrCleaning]: MtrCleaningIcon,
  [MewaCircleIcon.MtrChangeLiquid]: MtrChangeLiquidIcon,
  [MewaCircleIcon.MtrExchange]: MtrExchangeIcon,
  [MewaCircleIcon.MtrImmediateHelp]: MtrImmediateHelpIcon,
  [MewaCircleIcon.HeadProtection]: HeadProtectionIcon,
  [MewaCircleIcon.PreparationDin14644]: PreparationDin14644Icon,
};
