import React, { MouseEvent, useCallback } from 'react';

import { styled } from '@mui/material';

import cssVars from '../../styles/export-variables.module.scss';
import classes from './sticky-side-navigation.module.scss';

export type Props = {
  links: {
    id: string;
    label: string;
    isActive: boolean;
  }[];
  useWithHeader: boolean;
};

export const StickySideNavigation = ({ links, useWithHeader }: Props) => {
  const handleLinkClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      const targetId = e.currentTarget.hash?.substring(1);

      if (targetId) {
        e.preventDefault();

        const targetEl = document.getElementById(targetId);

        if (targetEl) {
          const headerOffset = useWithHeader
            ? parseInt(cssVars.headerHeightDesktop) + 30
            : 0;
          const clientR = targetEl.getBoundingClientRect();
          const targetScrollTop = clientR.top + window.scrollY - headerOffset;
          window.scrollTo({ top: targetScrollTop, behavior: 'smooth' });
        }
      }
    },
    [useWithHeader],
  );

  return (
    <StickyBehavior useWithHeader={useWithHeader} className={classes.stickyNav}>
      {links.map((link, idx) => (
        <li key={idx} className={link.isActive ? classes.active : ''}>
          <a href={`#${link.id}`} onClick={handleLinkClick}>
            <span className={classes.number}>
              {(idx + 1).toString().padStart(2, '0')}
            </span>{' '}
            <span className={classes.text}>{link.label}</span>
          </a>
        </li>
      ))}
    </StickyBehavior>
  );
};

const StickyBehavior = styled('ul')(
  ({ useWithHeader }: { useWithHeader: boolean }) => ({
    top: useWithHeader ? 'calc(var(--header-height) + 80px)' : '0px',
  }),
);
