import React, { PropsWithChildren } from 'react';

import { ServiceSegmentsTags } from './service-segments-tags';
import { ServiceSegmentsTheme, Tags } from './types';

export const ServiceSegment = <T extends React.ElementType>({
  children,
  tags,
  category,
  colorTheme,
  className = '',
  tagsComponent,
}: PropsWithChildren<{
  tags: Tags<T>[];
  category: string;
  colorTheme: ServiceSegmentsTheme;
  className?: string;
  tagsComponent: T;
}>) => {
  return (
    <div className={className}>
      {children}
      <ServiceSegmentsTags
        theme={colorTheme}
        tags={tags}
        category={category}
        tagsComponent={tagsComponent}
      />
    </div>
  );
};
