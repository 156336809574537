import { MewaAdvantagesIcon } from '@mewa/types';

import { ReactComponent as MewaAdvantagesCalendar } from './mewa_icon_calendar.svg';
import { ReactComponent as MewaAdvantagesCarefreeIcon } from './mewa_icon_carefree.svg';
import { ReactComponent as MewaAdvantagesCertificateIcon } from './mewa_icon_certificate.svg';
import { ReactComponent as MewaAdvantagesCostSavingIcon } from './mewa_icon_cost_saving.svg';
import { ReactComponent as MewaAdvantagesMbs1Icon } from './mewa_icon_mbs1.svg';
import { ReactComponent as MewaAdvantagesMbs2Icon } from './mewa_icon_mbs2.svg';
import { ReactComponent as MewaAdvantagesMbs3Icon } from './mewa_icon_mbs3.svg';
import { ReactComponent as MewaAdvantagesMbs4Icon } from './mewa_icon_mbs4.svg';
import { ReactComponent as MewaAdvantagesMbsComfortableIcon } from './mewa_icon_mbs_comfortable.svg';
import { ReactComponent as MewaAdvantagesMtsComfortableIcon } from './mewa_icon_mts_comfortable.svg';
import { ReactComponent as MewaAdvantagesMtsVarietyIcon } from './mewa_icon_mts_variety.svg';
import { ReactComponent as MewaAdvantagesQualityGuarantee } from './mewa_icon_quality_guarantee.svg';
import { ReactComponent as MewaAdvantagesSecureSupplyIcon } from './mewa_icon_secure_supply.svg';
import { ReactComponent as MewaAdvantagesSustainabilityIcon } from './mewa_icon_sustainability.svg';

export const mewaAdvantagesIconMap: Record<
  MewaAdvantagesIcon,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  [MewaAdvantagesIcon.MbsWideRange]: MewaAdvantagesMbs1Icon,
  [MewaAdvantagesIcon.MbsIndividualEquipment]: MewaAdvantagesMbs2Icon,
  [MewaAdvantagesIcon.MbsNoRiskOfConfusion]: MewaAdvantagesMbs3Icon,
  [MewaAdvantagesIcon.MbsInYourBrandDesign]: MewaAdvantagesMbs4Icon,
  [MewaAdvantagesIcon.MbsFlawlessHygiene]: MewaAdvantagesQualityGuarantee,
  [MewaAdvantagesIcon.MbsCarefreePackage]: MewaAdvantagesCarefreeIcon,
  [MewaAdvantagesIcon.MbsPureRelief]: MewaAdvantagesCalendar,
  [MewaAdvantagesIcon.MbsSecureSupplyInYourArea]:
    MewaAdvantagesSecureSupplyIcon,
  [MewaAdvantagesIcon.MbsSustainabilityInclusive]:
    MewaAdvantagesSustainabilityIcon,
  [MewaAdvantagesIcon.MbsComfortable]: MewaAdvantagesMbsComfortableIcon,
  [MewaAdvantagesIcon.MtsGreatVariety]: MewaAdvantagesMtsVarietyIcon,
  [MewaAdvantagesIcon.MtsQualityGuaranteed]: MewaAdvantagesQualityGuarantee,
  [MewaAdvantagesIcon.MtsPureRelief]: MewaAdvantagesCalendar,
  [MewaAdvantagesIcon.MtsSecureSupplyInYourArea]:
    MewaAdvantagesSecureSupplyIcon,
  [MewaAdvantagesIcon.MtsSustainabilityInclusive]:
    MewaAdvantagesSustainabilityIcon,
  [MewaAdvantagesIcon.MtsCarefreePackage]: MewaAdvantagesCarefreeIcon,
  [MewaAdvantagesIcon.MtsComfortable]: MewaAdvantagesMtsComfortableIcon,
  [MewaAdvantagesIcon.CostSaving]: MewaAdvantagesCostSavingIcon,
  [MewaAdvantagesIcon.Sustainable]: MewaAdvantagesSustainabilityIcon,
  [MewaAdvantagesIcon.Certificate]: MewaAdvantagesCertificateIcon,
};

export {
  MewaAdvantagesMbs1Icon,
  MewaAdvantagesMbs2Icon,
  MewaAdvantagesMbs3Icon,
  MewaAdvantagesMbs4Icon,
  MewaAdvantagesQualityGuarantee,
  MewaAdvantagesCarefreeIcon,
  MewaAdvantagesCalendar,
  MewaAdvantagesSustainabilityIcon,
  MewaAdvantagesMbsComfortableIcon,
  MewaAdvantagesMtsVarietyIcon,
  MewaAdvantagesMtsComfortableIcon,
  MewaAdvantagesSecureSupplyIcon,
  MewaAdvantagesCostSavingIcon,
  MewaAdvantagesCertificateIcon,
};
