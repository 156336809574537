import React from 'react';

import { Grid2 as Grid, styled, SxProps, Theme } from '@mui/material';

export type ResponsiveGridProps = React.PropsWithChildren<
  {
    sx?: SxProps<Theme>;
    id?: string;
    className?: string;
    gridProps?: React.ComponentProps<typeof Grid>;
  } & React.HTMLAttributes<HTMLDivElement>
>;

export const ResponsiveGrid = ({
  id,
  children,
  sx,
  className,
  gridProps,
  ...rest
}: ResponsiveGridProps) => (
  <GridWrapper id={id} sx={sx} className={className} {...rest}>
    <Grid container rowSpacing={0} {...gridProps}>
      {children}
    </Grid>
  </GridWrapper>
);

export default ResponsiveGrid;

export const GridWrapper = styled('div')({
  paddingLeft: 'var(--margin)',
  paddingRight: 'var(--margin)',
  boxSizing: 'border-box',
  transition:
    'padding var(--transition-duration) var(--transition-timing-cubic-bezier)',
});
