import React from 'react';

import { Button, ButtonProps, ButtonTypeMap } from '@mui/material';

import styles from './icon-text-button.module.scss';

type Props = ButtonProps<
  ButtonTypeMap['defaultComponent'],
  { target?: string }
> & {
  DefaultIcon: React.ElementType;
  HoverIcon?: React.ElementType;
};

/**
 * A text button including an icon with support for an optional `HoverIcon`.
 */
export const IconTextButton: React.FC<Props> = ({
  children,
  DefaultIcon,
  HoverIcon,
  ...rest
}) => (
  <Button
    {...rest}
    disableRipple
    variant="text"
    className={styles.iconTextButton}
    startIcon={
      <>
        <DefaultIcon className={HoverIcon ? styles.defaultIcon : undefined} />
        {HoverIcon && <HoverIcon className={styles.hoverIcon} />}
      </>
    }
  >
    {children}
  </Button>
);
