import cssVars from '../styles/export-variables.module.scss';

const breakpoints = {
  breakpoints: {
    values: {
      xs: parseInt(cssVars['maxWidthVp1']),
      sm: parseInt(cssVars['maxWidthVp2']),
      mdSm: parseInt(cssVars['maxWidthMedium']),
      md: parseInt(cssVars['maxWidthVp3']),
      lg: parseInt(cssVars['maxWidthVp4']),
      xl: parseInt(cssVars['maxWidthVp4']),
    },
  },
};

export default breakpoints;
