import React from 'react';

import { useVisibilityAnimation } from '@mewa/ui';

import { LinkData } from '../types';
import {
  SHOW_COLUMN_DELAY,
  VISIBILITY_ANIMATION_REMOVE_DELAY,
} from './animation-constants';
import { MenuColumn } from './link-columns';
import MenuWrapper from './menu-wrapper';

type Props = {
  langPrefix: string;
  show: boolean;
  columnStyle?: React.CSSProperties;
  columnData: MenuColumnData[];
  onClose: () => void;
};

const HeaderMenu = ({ langPrefix, show, columnData, onClose }: Props) => {
  const [renderUI, showUI] = useVisibilityAnimation(
    show,
    VISIBILITY_ANIMATION_REMOVE_DELAY,
  );

  return (
    <MenuWrapper
      renderUI={renderUI}
      showUI={showUI}
      show={show}
      onClose={onClose}
      transitionDelay={`${SHOW_COLUMN_DELAY + columnData.length * 80}ms`}
    >
      {renderMenuColumns(showUI, columnData, langPrefix)}
    </MenuWrapper>
  );
};

type MenuColumnData = {
  title?: string;
  links: LinkData[];
  slug?: string;
};

const renderMenuColumns = (
  showUI: boolean,
  columns: MenuColumnData[],
  langPrefix: string,
) => {
  const showColumnDelay = showUI ? SHOW_COLUMN_DELAY : 0;

  return columns.map((column, i) => (
    <MenuColumn
      key={i}
      title={column.title}
      slug={column.slug}
      show={showUI}
      langPrefix={langPrefix}
      links={column.links}
      transitionDelay={`${showColumnDelay + i * 80}ms`}
    />
  ));
};

export default HeaderMenu;
