import { useEffect, useRef, useState } from 'react';

/**
 * React hook to animate your UI in/out and disable rendering completely if the
 * UI is not longer visible.
 *
 * Based on the given `show` value you get back and array with two booleans.
 *
 * - renderUI: If true the element needs to be rendered
 * - showUI: If true the UI animation should be triggered (e.g. setting css classes)
 */
export const useVisibilityAnimation = (show: boolean, hideDelayInMs = 250) => {
  // Stores the timeout handle so we can cancel the animation if the `show`
  // prop changes before the previous animation finished.
  const timeoutHandle = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Determines if we should render anything at all.
  const [renderUI, setRenderUI] = useState(false);

  // Determines if we should show the UI (animate in/out)
  const [showUI, setShowUI] = useState(false);

  // Handles the show/hide animation based on the `show` input prop.
  useEffect(() => {
    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current);
    }

    if (show) {
      setRenderUI(true);
      timeoutHandle.current = setTimeout(() => {
        setShowUI(true);
      }, 100);
    } else {
      setShowUI(false);
      timeoutHandle.current = setTimeout(() => {
        setRenderUI(false);
      }, hideDelayInMs);
    }

    return () => {
      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current);
      }
    };
  }, [show, setRenderUI, setShowUI, hideDelayInMs]);

  return [renderUI, showUI];
};
