import React from 'react';

import { Link as GatsbyLink } from 'gatsby';

import { Grid2 as Grid, Link, styled } from '@mui/material';

import { Language, Market } from '@mewa/types';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  ResponsiveGrid,
  XIcon,
  XingIcon,
  YoutubeIcon,
} from '@mewa/ui';

import { marketsConfig } from '../../../libs/config/markets';
import t from '../../../libs/translate';
import { HeaderAndFooterTranslations } from '../header/types';
import styles from './footer.module.scss';

export type LinkConfig = {
  label: string;
  target: string;
  isExternal?: boolean;
  state?: Record<string, unknown>;
  onClick?: () => void;
};

type LinkProps = LinkConfig & {
  className?: string;
};

type FooterProps = {
  column1: LinkConfig[];
  column2: LinkConfig[];
  column3: LinkConfig[];
  translations: HeaderAndFooterTranslations;
  lang: Language;
  market: Market;
};

const FooterLink = ({
  label,
  target,
  state,
  onClick,
  className = '',
  isExternal,
}: LinkProps) =>
  isExternal ? (
    <Link
      component="a"
      href={target}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
      underline="none"
      className={`${styles.link} ${className}`}
    >
      {label}
    </Link>
  ) : (
    <Link
      component={GatsbyLink}
      to={target}
      className={`${styles.link} ${className}`}
      state={state}
      onClick={onClick}
      underline="none"
    >
      {label}
    </Link>
  );

export const Footer = ({
  column1,
  column2,
  column3,
  translations,
  lang,
  market,
}: FooterProps) => {
  const languagesForThisMarket = marketsConfig[market].languages;
  const languageDetails = marketsConfig[market].languages.find(
    (language) => language.code === lang,
  );
  const socialMedia = marketsConfig[market].socialMedia;

  return (
    <footer>
      <ResponsiveGrid className={styles.footer}>
        <Grid
          className={styles.linkContainer}
          size={{
            xs: 12,
            md: 3,
            xl: 3,
          }}
        >
          <MarketName>{languageDetails?.localizedMarketName}</MarketName>
          {languagesForThisMarket.length > 1 &&
            languagesForThisMarket.map((language) => (
              <FooterLink
                key={language.code}
                target={language.domain ?? `/${language.code}`}
                className={`${styles.link} ${styles.language}`}
                label={language.localizedLanguageLabel ?? ''}
              />
            ))}
        </Grid>
        <Grid
          className={styles.linkContainer}
          size={{
            xs: 12,
            md: 3,
            xl: 3,
          }}
        >
          {column1.map((linkConfig, i) => (
            <FooterLink key={i} {...linkConfig} className={styles.action} />
          ))}
        </Grid>
        <Grid
          className={styles.linkContainer}
          size={{
            xs: 12,
            md: 3,
            xl: 3,
          }}
        >
          {column2.map((linkConfig, i) => (
            <FooterLink key={i} {...linkConfig} className={styles.commonLink} />
          ))}
        </Grid>
        <Grid
          className={styles.linkContainer}
          size={{
            xs: 12,
            md: 3,
            xl: 3,
          }}
        >
          {column3.map((linkConfig, i) => (
            <FooterLink key={i} {...linkConfig} className={styles.commonLink} />
          ))}
        </Grid>

        <Spacer
          size={{
            xs: 12,
            md: 9,
            xl: 9,
          }}
        ></Spacer>

        {Object.keys(socialMedia).length > 1 && (
          <Grid
            sx={{ alignSelf: 'flex-end' }}
            className={styles.socialContainer}
            size={{
              xs: 12,
              md: 3,
              xl: 3,
            }}
          >
            {socialMedia.facebook && (
              <a
                href={socialMedia.facebook}
                target="_blank"
                className={styles.socialMediaLink}
                rel="noreferrer"
                aria-label="Facebook"
              >
                <FacebookIcon />
              </a>
            )}
            {socialMedia.x && (
              <a
                href={socialMedia.x}
                target="_blank"
                className={styles.socialMediaLink}
                rel="noreferrer"
                aria-label="X"
              >
                <XIcon />
              </a>
            )}
            {socialMedia.instagram && (
              <a
                href={socialMedia.instagram}
                target="_blank"
                className={styles.socialMediaLink}
                rel="noreferrer"
                aria-label="Instagram"
              >
                <InstagramIcon />
              </a>
            )}
            {socialMedia.youtube && (
              <a
                href={socialMedia.youtube}
                target="_blank"
                className={styles.socialMediaLink}
                rel="noreferrer"
                aria-label="YouTube"
              >
                <YoutubeIcon />
              </a>
            )}
            {socialMedia.xing && (
              <a
                href={socialMedia.xing}
                target="_blank"
                className={styles.socialMediaLink}
                rel="noreferrer"
                aria-label="XING"
              >
                <XingIcon />
              </a>
            )}
            {socialMedia.linkedin && (
              <a
                href={socialMedia.linkedin}
                target="_blank"
                className={styles.socialMediaLink}
                rel="noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedinIcon />
              </a>
            )}
          </Grid>
        )}

        <Grid size={12}>
          <Divider />
        </Grid>

        <Grid size={12}>
          <CopyrightNote>
            {t(translations, 'footer_copyright_note')}
          </CopyrightNote>
        </Grid>
      </ResponsiveGrid>
    </footer>
  );
};

export default Footer;

const MarketName = styled('p')(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: theme.typography.fontWeightRegular,
  marginBottom: '35px',
  marginTop: '-10px',
}));

const Spacer = styled(Grid)({});

const CopyrightNote = styled('div')(({ theme }) => ({
  marginTop: '38px',
  color: 'var(--color-medium-grey)',
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightRegular,
}));

const Divider = styled('hr')({
  border: 'none',
  height: '1px',
  backgroundColor: 'var(--color-medium-grey)',
  opacity: 0.2,
});
