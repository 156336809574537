import AutoplayVideo from './autoplay-video';
import VideoWithControls from './video-with-controls';

export type VideoSource = {
  src: string;
  type: string;
};

export type ResponsiveVideoProps = {
  desktopSources: VideoSource[];
  mobileSources: VideoSource[];
  posterImage: React.ReactElement | null;
  autoPlay: boolean;
};

export const ResponsiveVideo = (data: ResponsiveVideoProps) =>
  data.autoPlay ? (
    <AutoplayVideo
      previewSources={data.desktopSources}
      mobilePreviewSources={data.mobileSources}
    />
  ) : (
    <VideoWithControls
      posterImage={data.posterImage}
      desktopSources={data.desktopSources}
      mobileSources={data.mobileSources}
    />
  );
