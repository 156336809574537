import { RightGridColumn, TextContent } from './base';

export const TextBlock = ({
  isFirstElement,
  html,
}: {
  isFirstElement: boolean;
  html: string;
}) => (
  <RightGridColumn>
    <TextContent noTopElementSpacing={isFirstElement} html={html} />
  </RightGridColumn>
);
