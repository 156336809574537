import { styled } from '@mui/material';

import { PhoneIconLarge, PhoneIconSmall } from '../../../../assets/icons';

export type PhoneNumberLinkProps = {
  phoneNumber: string;
  officeHours: string;
  className?: string;
};

export const PhoneNumberLink = ({
  phoneNumber,
  officeHours,
  className,
}: PhoneNumberLinkProps) => (
  <Link href={`tel:${phoneNumber}`} className={className}>
    <DesktopIconWrapper>
      <PhoneIconLarge />
    </DesktopIconWrapper>
    <MobileIconWrapper>
      <PhoneIconSmall />
    </MobileIconWrapper>

    <div>
      <PhoneParagraph>{phoneNumber}</PhoneParagraph>
      <TimeParagraph>{officeHours}</TimeParagraph>
    </div>
  </Link>
);

export default PhoneNumberLink;

const Link = styled('a')({
  display: 'inline-flex',
  alignItems: 'center',
  color: 'var(--color-dark-coal)',
  transition: 'color 0.2s ease',

  '&:hover': {
    color: 'var(--color-light-red)',
  },
});

const DesktopIconWrapper = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileIconWrapper = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const PhoneParagraph = styled('p')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: 0,
  marginBottom: '5px',
  marginLeft: '20px',

  [theme.breakpoints.down('md')]: {
    marginBottom: '2px',
    marginLeft: '12px',
  },
}));

const TimeParagraph = styled('p')(({ theme }) => ({
  ...theme.typography.copySm,
  marginTop: 0,
  marginBottom: '15px',
  marginLeft: '20px',

  [theme.breakpoints.down('md')]: {
    marginLeft: '12px',
    marginBottom: '12px',
  },
}));
