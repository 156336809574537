import React from 'react';

import { styled } from '@mui/material';

import { cssVars, useVisibilityAnimation } from '@mewa/ui';

type Props = {
  show: boolean;
};

const MobileMenuLayer = ({
  children,
  show,
}: React.PropsWithChildren<Props>) => {
  const [renderUI, showUI] = useVisibilityAnimation(show);

  if (!renderUI) {
    return <span />;
  }

  return (
    <Container
      style={{
        opacity: showUI ? 1 : 0,
      }}
    >
      {children}
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'white',
  zIndex: 0,
  paddingTop: 'var(--header-height-open-menu)',
  transition:
    'opacity var(--transition-duration) var(--transition-timing-cubic-bezier)',

  [theme.breakpoints.up(cssVars.headerMobileMaxWidth)]: {
    display: 'none',
  },
}));

export default MobileMenuLayer;
