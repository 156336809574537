import { DataLayer } from '@piwikpro/react-piwik-pro';
import { useEffect, useRef } from 'react';

import type { LayoutPageContext } from '../../gatsby-pages/page-wrapper';
import { extractDeSlug } from '../helpers/domains';

type PiwikTrackerProps = {
  locationKey: string;
  pageContext: LayoutPageContext;
};

const PiwikTracker = ({ locationKey, pageContext }: PiwikTrackerProps) => {
  const previous = useRef('');

  useEffect(() => {
    const deHref = pageContext.alternatives?.find(
      (a) => a.locale === 'de-DE',
    )?.href;

    const deSlug = deHref ? extractDeSlug(deHref) : null;
    const pageHref = deSlug || window.location.pathname;

    // For now, we ignore URL hash changes when tracking global page changes.
    // We could still track those changes inside our hash navigation logic.
    if (previous.current !== pageHref) {
      previous.current = pageHref;

      DataLayer.push({
        DEHreflangPagePath: pageHref,
        Language: pageContext.locale,
      });
    }
  }, [locationKey, pageContext]);

  return <span />;
};

export default PiwikTracker;
