import { useRef, useState } from 'react';

import { Grid2 as Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import { SectionContent } from './section-content';
import { StickySideNavigation } from './sticky-side-navigation';

type SectionsWithStickyNavigationProps = {
  sections: {
    id: string;
    label: string;
    content: React.ReactElement;
  }[];
  useWithHeader?: boolean;
};

export const SectionsWithStickyNavigation = ({
  sections,
  useWithHeader = true,
}: SectionsWithStickyNavigationProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const minimalSlideHeight = useRef(0);

  const navLinks = sections.map((section, idx) => ({
    id: section.id,
    label: section.label,
    isActive: currentStep === idx,
  }));

  const handleIntersectionChange =
    (idx: number) => (_inView: boolean, entry: IntersectionObserverEntry) => {
      if (entry.rootBounds && entry.target) {
        const imageRect = entry.target.getBoundingClientRect();

        // For consistency, use the height of the smallest slide for calculations
        if (
          minimalSlideHeight.current === 0 ||
          minimalSlideHeight.current > imageRect.height
        ) {
          minimalSlideHeight.current = imageRect.height;
        }

        // limit the distance from screen edge to 'center of attention'
        const screenCenter = Math.min(
          entry.rootBounds.height / 2,
          minimalSlideHeight.current * 0.75,
        );
        const imageTopBound = imageRect.y;
        const imageBottomBound = imageRect.y + imageRect.height;

        const isImageInCenter =
          imageTopBound < screenCenter && screenCenter < imageBottomBound;

        const isPastLastImage =
          imageBottomBound < screenCenter && sections.length === idx + 1;

        const isPreFirstImage =
          imageTopBound > screenCenter && sections.length === 0;

        const wasAlreadyPassed = imageBottomBound < screenCenter;

        if (isImageInCenter || isPastLastImage || isPreFirstImage) {
          setCurrentStep(idx);
        } else if (wasAlreadyPassed) {
          // if an element is exiting the screen to the top, the next element is still
          // inside the viewport thus not triggering any events. We can still activate it though.
          // Because we do not know how many slides are below inside the viewport, we omit the
          // condition for the inverted case (element hasn't passed center yet --> setCurrentStep(idx-1))
          setCurrentStep(idx + 1);
        }
      }
    };

  return (
    <ResponsiveGrid>
      <SideNavGrid size={{ sm: 4, md: 3 }}>
        <StickySideNavigation links={navLinks} useWithHeader={useWithHeader} />
      </SideNavGrid>
      <Grid
        size={{
          sm: 1,
        }}
      />
      <Grid
        size={{
          xs: 12,
          mdSm: 7,
          md: 6,
        }}
      >
        {sections.map((section, idx) => (
          <SectionContent
            key={section.id}
            id={section.id}
            //
            // Whenever the intersection with the viewport changes, calculate if the element is the center
            // of attention. This needs to be done to allow images to be bigger than the viewport.
            // Intersections only change when a slide passes the edge of the screen, so the slide height is
            // the furthest the 'center of attention' can be away from the edge of the screen for an
            // intersection event to catch on an trigger an activation.
            onIntersectionChange={handleIntersectionChange(idx)}
          >
            {section.content}
          </SectionContent>
        ))}
      </Grid>
    </ResponsiveGrid>
  );
};

const SideNavGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('mdSm')]: {
    display: 'none',
  },
}));
