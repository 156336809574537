import { Grid2 as Grid, styled } from '@mui/material';

export const ImageGroup = ({ images }: { images: React.ReactElement[] }) => {
  return (
    <ImageGroupGrid container columns={12}>
      {images.map((image, idx) => (
        <ImageGridItem
          key={idx}
          size={{ xs: 12, sm: images.length < 2 ? 12 : 6 }}
        >
          {image}
        </ImageGridItem>
      ))}
    </ImageGroupGrid>
  );
};

const ImageGroupGrid = styled(Grid)(({ theme }) => ({
  marginTop: '45px',
  marginBottom: '45px',

  [theme.breakpoints.down('md')]: {
    marginTop: '35px',
    marginBottom: '35px',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '25px',
    marginBottom: '25px',
  },
}));

const ImageGridItem = styled(Grid)({
  marginTop: 'calc(var(--gutter) * 0.5)',
  marginBottom: 'calc(var(--gutter) * 0.5)',
});
