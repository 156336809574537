import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import { Button, Grid2 as Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../../layout/grid';
import { MarkdownContainer } from '../../markdown/markdown-container';
import { ConversionTeaserBase } from '../types';

export const ConversionTeaserLight = <T extends React.ElementType>({
  headline,
  content,
  labelCTA,
  link,
  piwikEventNameCTA,
  image,
  showCTA,
  customButton,
}: ConversionTeaserBase<T>) => (
  <ConversionContainer>
    <Grid
      size={{
        xs: 12,
        md: 7,
      }}
    >
      {image}
    </Grid>
    <Grid
      offset={{ xs: 0, md: 1 }}
      size={{
        xs: 12,
        md: 4,
      }}
    >
      <ConversionTitle>{headline}</ConversionTitle>
      <ConversionContent html={content} />
      {customButton
        ? customButton
        : showCTA && (
            <Button
              component={link.component}
              {...link.props}
              variant="outlined"
              color="primary"
              sx={{ width: ['100%', 'auto'] }}
              onClick={() => DataLayer.push({ event: piwikEventNameCTA })}
            >
              {labelCTA}
            </Button>
          )}
    </Grid>
  </ConversionContainer>
);

const ConversionContainer = styled(ResponsiveGrid)(({ theme }) => ({
  marginTop: '140px',

  [theme.breakpoints.down('md')]: {
    marginTop: '80px',
  },
}));

const ConversionTitle = styled('h2')(({ theme }) => ({
  ...theme.typography.h3,
  marginTop: '80px',
  marginBottom: '37px',
  width: '90%',

  [theme.breakpoints.down('md')]: {
    marginTop: '50px',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '40px',
    marginBottom: '30px',
  },
}));

const ConversionContent = styled(MarkdownContainer)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginBottom: '50px',
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: '55px',
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '60px',
  },
}));
