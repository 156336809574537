import React from 'react';

import {
  Button,
  ButtonTypeMap,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import ResponsiveGrid from '../../../layout/grid';

type Props<T extends React.ElementType> = {
  headline: string;
  description: string;
  imageComponent: React.ReactElement;
  cta: {
    component: T;
    label: string;
    onClick?: () => void;
    props: OverrideProps<ButtonTypeMap, T>;
  };
};

export const RoundImageTeaser = <T extends React.ElementType>({
  headline,
  description,
  imageComponent,
  cta,
}: Props<T>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GridContainer sx={{ marginTop: ['50px', '100px'] }}>
      <Container>
        <ImageContainer>{imageComponent}</ImageContainer>
        <ContentContainer>
          <Headline>
            <p>{headline}</p>
          </Headline>
          <Description>{description}</Description>
          {isMobile ? (
            <Button
              onClick={cta.onClick}
              variant="text"
              size="medium"
              {...cta.props}
              sx={{
                display: ['inline-flex'],
              }}
            >
              {cta.label}
            </Button>
          ) : (
            <Button
              onClick={cta.onClick}
              variant="contained"
              size="medium"
              {...cta.props}
              sx={{
                display: ['none', 'inline-flex'],
                marginTop: '15px',
              }}
            >
              {cta.label}
            </Button>
          )}
        </ContentContainer>
      </Container>
    </GridContainer>
  );
};

export default RoundImageTeaser;

const GridContainer = styled(ResponsiveGrid)(({ theme }) => ({
  backgroundColor: 'var(--color-light-grey)',
  paddingTop: '30px',
  paddingBottom: '30px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: '46px',
    paddingBottom: '58px',
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: '90px',
    paddingBottom: '100px',
  },
}));

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '1000px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'center',
}));

const ImageContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexBasis: '25%',
  paddingRight: '0',

  [theme.breakpoints.up('sm')]: {
    paddingRight: '50px',
    flexBasis: '300px',
    borderRight: '2px solid var(--color-light-red)',
  },

  [theme.breakpoints.up(1024)]: {
    paddingRight: '100px',
  },

  ' img': {
    maxHeight: '85px',
    maxWidth: '85px',

    [theme.breakpoints.up('sm')]: {
      maxHeight: '270px',
      maxWidth: '270px',
    },
  },
}));

const ContentContainer = styled('div')(({ theme }) => ({
  padding: '0 0 0 25px',
  flexBasis: '75%',

  [theme.breakpoints.up('sm')]: {
    flexBasis: '50%',
    padding: '0 0 0 50px',
  },

  [theme.breakpoints.up(1024)]: {
    padding: '0 0 0 100px',
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h4,
  lineHeight: '1.25',
  color: 'var(--color-dark-coal)',
  margin: 0,

  p: {
    margin: 0,
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: '10px',
  },

  [theme.breakpoints.up(1200)]: {
    marginBottom: '23px',
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '32px',
    lineHeight: '1.15',
  },
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  marginTop: '15px',
  marginBottom: '28px',
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
    marginBottom: '46px',
  },
}));
