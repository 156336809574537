import React from 'react';

import {
  createTheme,
  PaletteOptions as MuiPaletteOptions,
  Palette as MuiPallete,
  ThemeProvider,
} from '@mui/material';

import cssVars from '../styles/export-variables.module.scss';
import { accordion, accordionDetails, accordionSummary } from './accordion';
import autocomplete from './autocomplete';
import breakpoints from './breakpoints';
import button from './button';
import checkbox from './checkbox';
import formControl from './formComtrol';
import grid from './grid';
import { link } from './link';
import radioButton from './radio-button';
import { step, stepLabel, stepper } from './stepper';
import textField from './textfield';
import { tooltip } from './tooltip';
import { typography, variantMapping } from './typography';

const cssSupportingJest =
  process.env['NODE_ENV'] === 'test'
    ? {
        colorLightRed: '#e60021',
        colorDarkRed: '#be0f28',
        colorDarkCoal: '#222',
        colorBlack: '#000',
        colorWhite: '#fff',
      }
    : cssVars;

const themeConfigBase = createTheme({
  typography: typography,

  palette: {
    primary: {
      main: cssSupportingJest.colorLightRed,
      dark: cssSupportingJest.colorDarkRed,
    },
    inverted: {
      main: cssSupportingJest.colorWhite,
    },
    secondary: {
      main: cssSupportingJest.colorDarkCoal,
      dark: cssSupportingJest.colorBlack,
    },
  },

  shape: {
    borderRadius: 0,
  },

  ...breakpoints,
});

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mdSm: true; // adds the `mdSm` (medium small) breakpoint

    // You could also remove the original breakpoints like so
    //xs: false;
  }

  interface Palette {
    inverted: MuiPallete['primary'];
  }
  interface PaletteOptions {
    inverted?: MuiPaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    inverted: true;
  }
}

// Create the components after we create the initial theme, so we can access
// things like `themeConfig.breakpoint` inside our components.
const themeConfig = createTheme({
  ...themeConfigBase,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: variantMapping,
      },
    },
    MuiGrid2: grid,
    MuiButton: button(themeConfigBase),
    MuiLink: link(themeConfigBase),
    MuiTextField: textField(themeConfigBase),
    MuiStepper: stepper(themeConfigBase),
    MuiStep: step(themeConfigBase),
    MuiStepLabel: stepLabel(themeConfigBase),
    MuiRadio: radioButton,
    MuiCheckbox: checkbox,
    MuiAutocomplete: autocomplete,
    MuiAccordion: accordion,
    MuiAccordionSummary: accordionSummary(themeConfigBase),
    MuiAccordionDetails: accordionDetails(themeConfigBase),
    MuiTooltip: tooltip(themeConfigBase),
    MuiFormControl: formControl(themeConfigBase),
  },
});

export const Theme = ({ children }: React.PropsWithChildren) => (
  <ThemeProvider theme={themeConfig}>{children}</ThemeProvider>
);

export default Theme;
