import { Language, Market, MarketLanguage } from '@mewa/types';

import { getLanguagesForMarket } from '../languages-for-market';
import { marketsConfig } from './markets';

export const getLocalizedLink = (
  portal: 'customerPortal' | 'careerPortal' | 'onlineShop',
  market: Market,
  lang: Language,
): string | undefined => {
  const languageDetails = marketsConfig[market].languages.find(
    (language) => language.code === lang,
  );

  return languageDetails?.[portal];
};

export const getLocalizedMandatoryLink = (
  linkType: 'customerPortal' | 'careerPortal',
  market: Market,
  lang: Language,
): string => {
  const localizedMandatoryLink = getLocalizedLink(linkType, market, lang);

  if (!localizedMandatoryLink) {
    throw new Error(`Missing link for ${linkType} in ${lang}-${market}`);
  }

  return localizedMandatoryLink;
};

export const getMarketDomain = (market: Market) => {
  const marketConfig = marketsConfig[market];

  if (marketConfig.domain) {
    return marketConfig.domain;
  }

  const languages = getLanguagesForMarket(market);

  const languageData = marketConfig.languages.find(
    (lang) => lang.code === languages[0],
  );

  // all languages for this market will be available in the markets data (checked by TypeScript) so we can cast to MarketLanguage
  return (languageData as MarketLanguage).domain;
};
