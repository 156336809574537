import { useCallback, useRef, useState } from 'react';

import { useWindowResize } from '../../../hooks/window-resize';
import cssVars from '../../../styles/export-variables.module.scss';

export type SliderViewport = 'sm' | 'medium' | 'md' | 'lg' | 'xl';

const VP2_WIDTH = parseInt(cssVars.maxWidthVp2);
const VP_MEDIUM = parseInt(cssVars.maxWidthMedium);
const VP3_WIDTH = parseInt(cssVars.maxWidthVp3);
const VP4_WIDTH = parseInt(cssVars.maxWidthVp4);

const viewportForWidth = (width: number): SliderViewport => {
  if (width < VP2_WIDTH) {
    return 'sm';
  }

  if (width < VP_MEDIUM) {
    return 'medium';
  }

  if (width < VP3_WIDTH) {
    return 'md';
  }

  if (width < VP4_WIDTH) {
    return 'lg';
  }

  return 'xl';
};

export const collectionSliderColumnsForViewport = (
  vp: SliderViewport,
): number => {
  if (vp === 'xl' || vp === 'lg') {
    return 4;
  }

  if (vp === 'md') {
    return 3;
  }

  return 1.1;
};

export const columnsForCollectionSlider = [
  { minWidth: 0, columns: 1.1 },
  { minWidth: 600, columns: 1.7 },
  { minWidth: 900, columns: 2.1 },
  { minWidth: 1000, columns: 2.6 },
  { minWidth: 1200, columns: 3.2 },
  { minWidth: 1440, columns: 4 },
  { minWidth: 1800, columns: 5 },
  { minWidth: 2000, columns: 5 },
];

export const overviewSliderColumnsForViewport = (
  vp: SliderViewport,
): number => {
  if (vp === 'xl' || vp === 'lg') {
    return 3;
  }

  if (vp === 'md') {
    return 2;
  }

  return 1.2;
};

/**
 * Hook to calculate the current slider viewport based on the window size.
 * Separating this calculation from the slider itself allows us to use a single
 * resize callback per page and reuse the calculated viewport for all sliders
 * on the page.
 */
export const useSliderViewport = () => {
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1024;

  const [viewport, setViewport] = useState(viewportForWidth(windowWidth));
  const viewportRef = useRef(viewportForWidth(windowWidth));

  const onResize = useCallback(() => {
    const newViewport = viewportForWidth(window.innerWidth);
    if (viewportRef.current !== newViewport) {
      viewportRef.current = newViewport;
      setViewport(newViewport);
    }
  }, [setViewport]);

  useWindowResize(onResize);

  return viewport;
};

export type ColumnsMQSizeMap = { minWidth: number; columns: number }[];

const getNewMqIndex = (sizes: ColumnsMQSizeMap, windowWidth: number) => {
  const index = sizes.findIndex((s) => s.minWidth >= windowWidth);
  return index >= 1 ? index - 1 : sizes.length - 1;
};

export const useSliderWidth = (sizes: ColumnsMQSizeMap) => {
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1024;
  const [mqIndex, setMqIndex] = useState(getNewMqIndex(sizes, windowWidth));
  const mqIndexRef = useRef(getNewMqIndex(sizes, windowWidth));

  const onResize = useCallback(() => {
    const newMqIndex = getNewMqIndex(sizes, window.innerWidth);
    if (newMqIndex > -1 && mqIndexRef.current !== newMqIndex) {
      setMqIndex(newMqIndex);
      mqIndexRef.current = newMqIndex;
    }
  }, [setMqIndex, sizes]);

  useWindowResize(onResize);

  return sizes[mqIndex].columns;
};
