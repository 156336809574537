import React, { useState } from 'react';

import { Grid2 as Grid, styled, useMediaQuery, useTheme } from '@mui/material';

import { TextWithMarking } from '@mewa/types';

import ResponsiveGrid from '../../layout/grid';
import {
  VideoWithControlProps,
  VideoWithControls,
} from '../media/video-with-controls';

export const StageWithVideo = ({
  title,
  subtitle,
  video,
}: {
  title: TextWithMarking;
  subtitle: string;
  video: Omit<VideoWithControlProps, 'onPlay'>;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const textLayer = React.useMemo(
    () => (
      <TextContainer>
        <ResponsiveGrid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Title>
              {title.beforeMarking}
              <strong>{title.marking}</strong>
              {title.afterMarking}
            </Title>
            <Subtitle>{subtitle}</Subtitle>
          </Grid>
        </ResponsiveGrid>
      </TextContainer>
    ),
    [title, subtitle],
  );

  return (
    <Container>
      <StyledVideo {...video} controls onPlay={setIsPlaying}>
        {!isMobile && !isPlaying ? textLayer : null}
      </StyledVideo>
      {isMobile ? textLayer : null}
    </Container>
  );
};

const TextContainer = styled('div')(({ theme }) => ({
  marginTop: '24px',
  paddingBottom: 0,

  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    marginTop: 0,
    pointerEvents: 'none',
    paddingTop: '40px',
    zIndex: 1,
    background:
      'linear-gradient(90deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 50%)',
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: '110px',
  },
}));

const StyledVideo = styled(VideoWithControls)(() => ({ theme }) => ({
  aspectRatio: 1.66667,

  [theme.breakpoints.up('sm')]: {
    aspectRatio: 2.13,
  },
}));

const Container = styled('div')({
  position: 'relative',
});

const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  margin: 0,
  color: 'var(--color-dark-coal)',

  [theme.breakpoints.up('sm')]: {
    color: 'var(--color-white)',
  },

  strong: {
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
  },
}));

const Subtitle = styled('h2')(({ theme }) => ({
  ...theme.typography.h1,
  margin: 0,
  color: 'var(--color-dark-coal)',

  [theme.breakpoints.up('sm')]: {
    color: 'var(--color-white)',
  },
}));
