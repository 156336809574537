import { Button, buttonClasses, styled } from '@mui/material';

export const IconButton = styled(Button)({
  padding: '0 !important',
  [`.${buttonClasses.startIcon}`]: {
    margin: 0,
  },

  '&:after': {
    display: 'none',
  },
});

export const VideoContainer = styled('div')(({ theme }) => ({
  aspectRatio: '16/9',
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',

  '.play-icon-desktop': {
    display: 'none',
    zIndex: 1,
  },

  '.play-icon-mobile': {
    zIndex: 1,
  },

  '.pause-button': {
    display: 'none',
  },

  '&:hover': {
    '.pause-button': {
      display: 'block',
    },
  },

  [theme.breakpoints.up('sm')]: {
    '.play-icon-desktop': {
      display: 'block',
    },

    '.play-icon-mobile': {
      display: 'none',
    },
  },
}));

export const Video = styled('video')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
