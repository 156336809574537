import { Button, styled } from '@mui/material';

type Props = {
  headline1: string;
  headline2: string;
  description: string;
  cta: {
    label: string;
    onClick: () => void;
  };
  className?: string;
};

export const ConversionTeaserSide = ({
  headline1,
  headline2,
  description,
  cta: { label, onClick },
  className = '',
}: Props) => (
  <Container className={className}>
    <Headline>
      <p>{headline1}</p>
      <p>{headline2}</p>
    </Headline>
    <Description>{description}</Description>
    <Button variant="contained" onClick={onClick}>
      {label}
    </Button>
  </Container>
);

const Container = styled('div')(({ theme }) => ({
  borderTop: '2px solid var(--color-light-red)',
  padding: '24px 0 0 0',
  marginTop: '46px',

  [theme.breakpoints.up('sm')]: {
    borderLeft: '2px solid var(--color-light-red)',
    paddingLeft: '44px',
    paddingBottom: '44px',
    borderTop: 'none',
    marginTop: '66px',
  },
}));

const Headline = styled('h4')(({ theme }) => ({
  ...theme.typography.h4,
  margin: 0,

  p: {
    margin: 0,
  },
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  marginTop: '15px',
  marginBottom: '28px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '46px',
  },
}));
