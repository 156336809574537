import React from 'react';

import { Link, LinkTypeMap, styled, SxProps, Theme } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import { ConsultationArrow } from '../../../assets/icons';

type Props<T extends React.ElementType> = {
  className?: string;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  link: {
    component: T;
    props: OverrideProps<LinkTypeMap, T>;
  };
};

export const ConsultationLink = <T extends React.ElementType>({
  children,
  onClick,
  className,
  link,
  sx,
}: React.PropsWithChildren<Props<T>>) => {
  return (
    <StyledLink
      component={link.component}
      onClick={onClick}
      color="secondary"
      underline="none"
      tabIndex={0}
      sx={sx}
      {...link.props}
      className={className}
    >
      <Circle>
        <ConsultationArrow />
      </Circle>
      {children}
    </StyledLink>
  );
};

const Circle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  aspectRatio: '1/1',
  marginRight: '10px',
  border: '2px solid var(--color-light-red)',
  borderRadius: '50%',
  fill: 'var(--color-light-red)',
});

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.typography.fontWeightMedium,
  color: 'var(--color-light-red)',

  '&:hover': {
    div: {
      transitionDuration: 'var(--transition-duration)',
      backgroundColor: 'var(--color-light-red)',
      fill: 'var(--color-white)',
    },
  },
})) as typeof Link;
