import React from 'react';

import { styled } from '@mui/material';

import { ResponsiveVideo, ResponsiveVideoProps } from './responsive-video';

export type VideoWithCaptionProps = ResponsiveVideoProps & {
  caption?: string | null;
};

export const VideoWithCaption = ({
  caption,
  ...videoProps
}: VideoWithCaptionProps) => (
  <>
    <ResponsiveVideo {...videoProps} />
    {caption && <Caption>{caption}</Caption>}
  </>
);

const Caption = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  borderLeft: '2px solid var(--color-light-red)',
  margin: '28px 0 0',
  paddingLeft: '11px',
  maxWidth: '91%',

  [theme.breakpoints.up('sm')]: {
    paddingLeft: '17px',
    maxWidth: '66.67%',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
}));
