import React from 'react';

import { Button, ButtonTypeMap, Grid2 as Grid, styled } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import LabeledNumber, { LabeledNumberData } from '../numbers/labeled-number';

type Props<T extends React.ElementType> = {
  label: string;
  number1?: LabeledNumberData;
  number2?: LabeledNumberData;
  mobileHeadlineNumber: number;
  mobileHeadlineText: string;
  locale?: string;
  image: React.ReactElement;
  link?: {
    text: string;
    component: T;
    props: OverrideProps<ButtonTypeMap, T>;
  };
};

export const LabeledImage = <T extends React.ElementType>({
  label,
  number1,
  number2,
  mobileHeadlineNumber,
  mobileHeadlineText,
  locale,
  image,
  link,
}: Props<T>) => {
  return (
    <LabeledImageContainer>
      <MobileImageHeadline>
        <HeadlineNumber>
          {mobileHeadlineNumber.toString().padStart(2, '0')}
        </HeadlineNumber>
        {mobileHeadlineText}
      </MobileImageHeadline>
      {image}
      <ImageLabel>{label}</ImageLabel>

      {(number1 || number2) && (
        <NumberContainer>
          <Grid container>
            {number1 && (
              <Grid
                size={{
                  xs: 11,
                  sm: 7,
                  md: 6,
                }}
              >
                <LabeledNumber
                  sx={{ width: '100%' }}
                  size="small"
                  labelPosition="top"
                  locale={locale}
                  {...number1}
                />
              </Grid>
            )}
            {number2 && (
              <Grid
                size={{
                  xs: 11,
                  sm: 7,
                  md: 6,
                }}
              >
                <SecondNumber
                  sx={{ width: '100%' }}
                  size="small"
                  labelPosition="top"
                  locale={locale}
                  {...number2}
                />
              </Grid>
            )}
          </Grid>
        </NumberContainer>
      )}

      {link && (
        <Button
          {...link.props}
          component={link.component}
          variant="text"
          size="medium"
        >
          {link.text}
        </Button>
      )}
    </LabeledImageContainer>
  );
};

const LabeledImageContainer = styled('div')(({ theme }) => ({
  '&:first-of-type': {
    marginTop: 0,
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '50px',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
}));

const ImageLabel = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  width: '90%',
  marginTop: '40px',
  marginBottom: 0,

  [theme.breakpoints.down('sm')]: {
    marginTop: '24px',
  },
}));

const NumberContainer = styled('div')(({ theme }) => ({
  marginTop: '60px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '38px',
  },
}));

const SecondNumber = styled(LabeledNumber)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: '36px',
  },
}));

const MobileImageHeadline = styled('p')(({ theme }) => ({
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightMedium,
  margin: 0,
  borderTop: '1px solid var(--color-medium-light-grey)',
  paddingTop: '16px',
  paddingBottom: '16px',

  [theme.breakpoints.up('mdSm')]: {
    display: 'none',
  },
}));

const HeadlineNumber = styled('span')(({ theme }) => ({
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightRegular,
  color: 'var(--color-medium-grey)',
  marginRight: '10px',
}));
