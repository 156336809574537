import { ThemeOptions } from '@mui/material';

export const typography: ThemeOptions['typography'] = {
  fontFamily: 'var(--font-family)',

  h1: {
    fontSize: 'var(--font-size-h1)',
    lineHeight: 'var(--line-height-h1)',
    fontWeight: '300',
  },

  h2: {
    fontSize: 'var(--font-size-h2)',
    lineHeight: 'var(--line-height-h2)',
    fontWeight: '500',
  },

  h3: {
    fontSize: 'var(--font-size-h3)',
    lineHeight: 'var(--line-height-h3)',
    fontWeight: '500',
  },

  h4: {
    fontSize: 'var(--font-size-h4)',
    lineHeight: 'var(--line-height-h4)',
    fontWeight: '500',
  },

  h5: {
    fontWeight: '500',
    fontSize: 'var(--font-size-h5)',
    lineHeight: 'var(--line-height-h5)',
  },

  h6: {
    fontWeight: '500',
    fontSize: 'var(--font-size-h6)',
    lineHeight: 'var(--line-height-h6)',
  },

  copy: {
    fontSize: 'var(--font-size-copy)',
    lineHeight: 'var(--line-height-copy)',
    fontWeight: '400',
  },

  copySm: {
    fontSize: 'var(--font-size-small-copy)',
    lineHeight: 'var(--line-height-small-copy)',
    fontWeight: '400',
  },
};

export default typography;

export const variantMapping = {
  copy: 'p',
  copySm: 'p',
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    copy: React.CSSProperties;
    copySm: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    copy?: React.CSSProperties;
    copySm?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    copy: true;
    copySm: true;
  }
}
