import { styled } from '@mui/material';

import {
  ConversionButton,
  ConversionButtonProps,
} from '../conversion-button/conversion-button';
import { RightGridColumn, TextContent } from './base';

export type IntroTextProps<T extends React.ElementType> = {
  isFirstElement: boolean;
  html: string;
  showCTA: boolean;
  link: ConversionButtonProps<T>['link'];
  ctaLabel: string;
  onCtaClick?: () => void;
};

export const IntroText = <T extends React.ElementType>({
  isFirstElement,
  html,
  showCTA,
  link,
  ctaLabel,
  onCtaClick,
}: IntroTextProps<T>) => (
  <StyledRightGridColumn>
    <IntroTextContent noTopElementSpacing={isFirstElement} html={html} />
    {showCTA && (
      <ConversionButton
        link={link}
        sx={{ marginTop: ['19px', '64px'], width: 'auto' }}
        onClick={onCtaClick}
        variant="contained"
      >
        {ctaLabel}
      </ConversionButton>
    )}
  </StyledRightGridColumn>
);

const StyledRightGridColumn = styled(RightGridColumn)(({ theme }) => ({
  marginBottom: 0,

  ':not(:last-child)': {
    marginBottom: '46px',

    [theme.breakpoints.up('sm')]: {
      marginBottom: '80px',
    },
  },
}));

const IntroTextContent = styled(TextContent)(({ theme }) => ({
  p: {
    ...theme.typography.h4,
    maxWidth: '990px',
  },

  'p:last-child': {
    marginBottom: 0,
  },
}));
