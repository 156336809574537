const extractDeSlugRegex = /https:\/\/mewa\.de(\/?.*)/;

export const extractDeSlug = (fullPath: string): string | null => {
  const match = fullPath.match(extractDeSlugRegex);

  if (match) {
    return match[1] || '/';
  }

  return null;
};
