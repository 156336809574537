import React from 'react';

import { Link as GatsbyLink } from 'gatsby';

import { styled } from '@mui/material';

import { ServiceSegmentType } from '@mewa/types';
import { cssVars } from '@mewa/ui';

import t from '../../../libs/translate';
import {
  SearchPageTranslations,
  ServiceSegmentLink,
} from '../../components/header/types';
import {
  PageFindResultData,
  ProductSearchResultMeta,
  searchResultsAreProducts,
  SearchSections,
} from '../../components/search/types';
import {
  CategorySection,
  LinkDescription,
  LinkedTitle,
  LinkTitle,
  ProductSection,
  SearchLink,
} from './flyout-sections';

export const SearchFlyout = ({
  className = '',
  translations,
  results,
  totalCount: total,
  serviceSegmentLinks,
  isClothing,
  langPrefix,
  onClickFlyoutItem,
}: {
  results: SearchSections;
  totalCount: number;
  translations: SearchPageTranslations;
  className?: string;
  serviceSegmentLinks: ServiceSegmentLink[];
  isClothing: boolean;
  langPrefix: string;
  onClickFlyoutItem: (recommendation: string) => void;
}) => {
  const products = results.productDetail;

  return (
    <FlyoutContainer className={className}>
      <ContentMargins>
        {total > 0 ? (
          <>
            {searchResultsAreProducts(products)
              ? (products as PageFindResultData<ProductSearchResultMeta>[])
                  .slice(0, 5)
                  .map((product) => (
                    <ProductSection
                      key={product.url}
                      searchResult={product}
                      onClick={() => {
                        onClickFlyoutItem('SearchFlyoutUsed');
                      }}
                    />
                  ))
              : null}

            {results.category.slice(0, 4).map((categoryResult) => (
              <CategorySection
                key={categoryResult.url}
                searchResult={categoryResult}
                translations={translations}
                onClick={() => {
                  onClickFlyoutItem('SearchFlyoutUsed');
                }}
              />
            ))}

            {results.brandTopic.slice(0, 1).map((brandTopic) => (
              <SearchLink
                key={brandTopic.id}
                to={brandTopic.url}
                title={brandTopic.meta.title}
                onClick={() => {
                  onClickFlyoutItem('SearchFlyoutUsed');
                }}
              >
                <LinkTitle>{brandTopic.meta.title}</LinkTitle>
              </SearchLink>
            ))}

            {results.pressRelease.slice(0, 1).map((pressResult) => (
              <SearchLink
                key={pressResult.id}
                to={pressResult.url}
                title={pressResult.meta.title}
                onClick={() => {
                  onClickFlyoutItem('SearchFlyoutUsed');
                }}
              >
                <LinkTitle>{pressResult.meta.title}</LinkTitle>
                <LinkDescription>
                  {t(translations, 'search_page_article_link', {
                    amount: '',
                  })}
                </LinkDescription>
              </SearchLink>
            ))}
            {results.blogArticle.slice(0, 1).map((blogArticleResult) => (
              <SearchLink
                key={blogArticleResult.id}
                to={blogArticleResult.url}
                title={blogArticleResult.meta.title}
                onClick={() => {
                  onClickFlyoutItem('SearchFlyoutUsed');
                }}
              >
                <LinkTitle>{blogArticleResult.meta.title}</LinkTitle>
                <LinkDescription>
                  {t(translations, 'search_page_blog_article_link', {
                    amount: '',
                  })}
                </LinkDescription>
              </SearchLink>
            ))}
          </>
        ) : (
          <FrequentlySearched
            title={t(translations, 'general_search_modal_frequent_searches')}
            isClothing={isClothing}
            langPrefix={langPrefix}
            serviceSegmentLinks={serviceSegmentLinks}
            onClickItem={() => {
              onClickFlyoutItem('SearchRecommended');
            }}
          />
        )}
      </ContentMargins>
    </FlyoutContainer>
  );
};
const FlyoutContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: 'calc(100dvh - 20px - 46px)',
  overflowY: 'auto',
  backgroundColor: 'var(--color-white)',
  width: '100vw',
  zIndex: '1',
  bottom: '0px',
  left: '50%',
  transform: 'translate(-50%, 100%)',
  opacity: 0,
  visibility: 'hidden', // to avoid user interactions and provide better accessibility on hidden flyout
  transition:
    'opacity var(--transition-duration), visibility var(--transition-duration), background-color var(--transition-duration)',

  [theme.breakpoints.down(cssVars.mobileSearchMaxWidth)]: {
    paddingTop: '20px',
    transition: 'none',
  },

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    width: '100%',
    height: 'auto',
    bottom: '1px', // remove 1px to equalize the possible border of the header
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
}));

const ContentMargins = styled('div')(({ theme }) => ({
  marginBottom: '42px',
  marginTop: '5px',
  paddingLeft: 'var(--margin)',
  paddingRight: 'var(--margin)',

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    paddingLeft: '35px',
    paddingRight: '35px',
  },
}));

const FrequentlySearched = ({
  title,
  isClothing,
  serviceSegmentLinks,
  langPrefix,
  onClickItem,
}: {
  title: string;
  isClothing: boolean;
  serviceSegmentLinks: ServiceSegmentLink[];
  langPrefix: string;
  onClickItem: () => void;
}) => {
  const serviceSegmentLinkMap = Object.fromEntries(
    serviceSegmentLinks.map((l) => [l.key, l]),
  );

  const frequentlySearchedLinks = (
    isClothing
      ? [
          serviceSegmentLinkMap[ServiceSegmentType.workwear],
          serviceSegmentLinkMap[ServiceSegmentType.protectiveClothing],
          serviceSegmentLinkMap[ServiceSegmentType.hygieneClothing],
          serviceSegmentLinkMap[ServiceSegmentType.healthClothing],
          serviceSegmentLinkMap[ServiceSegmentType.businessClothing],
          serviceSegmentLinkMap[ServiceSegmentType.serviceClothing],
          serviceSegmentLinkMap[ServiceSegmentType.cleaningCloths],
        ]
      : [
          serviceSegmentLinkMap[ServiceSegmentType.cleaningCloths],
          serviceSegmentLinkMap[ServiceSegmentType.washroomHygiene],
          serviceSegmentLinkMap[ServiceSegmentType.carCovers],
          serviceSegmentLinkMap[ServiceSegmentType.oilCollectionMats],
          serviceSegmentLinkMap[ServiceSegmentType.partsCleaner],
          serviceSegmentLinkMap[ServiceSegmentType.dirtTrappingMats],
          serviceSegmentLinkMap[ServiceSegmentType.wipingMops],
        ]
  ).filter((link) => link);

  return (
    <>
      <Title>{title}</Title>
      <ServiceSegments>
        {frequentlySearchedLinks.map((link) => (
          <li key={link.key}>
            <LinkedTitle
              underline="none"
              color="secondary"
              component={GatsbyLink}
              onClick={onClickItem}
              to={`${langPrefix}/${link.slug}`}
            >
              {link.name}
            </LinkedTitle>
          </li>
        ))}
      </ServiceSegments>
    </>
  );
};

const ServiceSegments = styled('ul')({
  listStyle: 'none',
  paddingLeft: 0,

  li: {
    marginBottom: '25px',
  },
});

const Title = styled('p')(({ theme }) => ({
  ...theme.typography.copySm,
  fontWeight: 'unset',
  marginBottom: '18px',
}));
