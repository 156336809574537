import { Components, Theme } from '@mui/material';

import cssVars from '../styles/export-variables.module.scss';

const grid: Components<Theme>['MuiGrid'] = {
  defaultProps: {
    columnSpacing: {
      xs: cssVars.gutterVp1,
      sm: cssVars.gutterVp2,
      md: cssVars.gutterVp3,
      lg: cssVars.gutterVp4,
    },
  },
};

export default grid;
