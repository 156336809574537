import { ASPECT_RATIO_PORTRAIT } from '../cards/aspect-ratios';
import {
  LinkedImageCardSlide,
  LinkedImageCardSlideProps,
} from '../cards/linked-image-card-slide';
import { FixedColumnSlider, SliderProps } from '../core/fixed-column-slider';
import {
  overviewSliderColumnsForViewport,
  SliderViewport,
} from '../core/viewport';

export type OverviewSliderProps = {
  title?: string;
  slides: Slide[];
  aspectRatio?: string;
  viewport: SliderViewport;
  headingComponent?: SliderProps<Slide>['headingComponent'];
};

type Slide = {
  image: LinkedImageCardSlideProps['image'];
  hoverImage?: LinkedImageCardSlideProps['hoverImage'];
  wrapperLink: LinkedImageCardSlideProps['wrapperLink'];
  linkComponent?: LinkedImageCardSlideProps['linkComponent'];
  description?: LinkedImageCardSlideProps['description'];
  linkDescription?: LinkedImageCardSlideProps['linkDescription'];
};

// NOTE: this offset is used to align the slider controls in the center of
// the image of the slide (there is also a text link below the slide). This is
// a really simple and pragmatic solution which assumes that the link in each
// card fits on a single line. If this is not the case for all languages we
// might need to find a different solution.
const controlsOffset = '-33px';

export const OverviewSlider = ({
  title,
  slides,
  aspectRatio,
  viewport,
  headingComponent,
}: OverviewSliderProps) => (
  <FixedColumnSlider
    title={title}
    slides={slides}
    renderSlide={renderSlide(aspectRatio || ASPECT_RATIO_PORTRAIT)}
    viewport={viewport}
    withCardSpacing
    getColumnsCount={overviewSliderColumnsForViewport}
    controlsOffset={controlsOffset}
    headingComponent={headingComponent}
  />
);

export default OverviewSlider;

const renderSlide = (aspectRatio: string) => (slide: Slide, idx: number) => {
  return (
    <LinkedImageCardSlide
      key={idx}
      aspectRatio={aspectRatio}
      image={slide.image}
      hoverImage={slide.hoverImage}
      wrapperLink={slide.wrapperLink}
      linkComponent={slide.linkComponent}
      description={slide.description}
      linkDescription={slide.linkDescription}
    />
  );
};
