import React, { ReactNode } from 'react';

import { styled } from '@mui/material';

import { CloseIcon, cssVars, ResponsiveGrid } from '@mewa/ui';

import { CONTAINER_REMOVE_ANIMATION_TIME } from './animation-constants';

type Props = {
  show: boolean;
  renderUI: boolean;
  showUI: boolean;
  children: ReactNode;
  onClose: () => void;
  transitionDelay: string;
};

const MenuWrapper = ({
  showUI,
  renderUI,
  children,
  onClose,
  transitionDelay,
}: Props) => {
  // return early if we don't want to render the UI anymore
  if (!renderUI) {
    return <span />;
  }

  return (
    <MenuContainer
      style={{
        opacity: showUI ? 1 : 0,
        transitionDelay: showUI ? '200ms' : '0ms',
      }}
    >
      <ResponsiveGrid
        sx={{
          maxHeight: 'calc(100vh - var(--header-height))',
          overflow: 'auto', // just in case the window height is very small
          position: 'relative',
        }}
      >
        {children}
        <CloseIconWrapper
          style={{ opacity: showUI ? 1 : 0, transitionDelay: transitionDelay }}
        >
          <Icon onClick={onClose}>
            <CloseIcon />
          </Icon>
        </CloseIconWrapper>
      </ResponsiveGrid>
    </MenuContainer>
  );
};

const CloseIconWrapper = styled('div')({
  position: 'absolute',
  right: 'calc(var(--margin) - 35px)',
  top: '-30px',
  transition: 'opacity var(--transition-duration)',
});

const Icon = styled('button')({
  color: 'var(--color-dark-coal)',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',

  '&:hover': {
    color: 'var(--color-light-red)',
  },

  svg: {
    width: '80px',
    height: '80px',
  },
});

export default MenuWrapper;

const MenuContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'var(--color-white)',
  boxShadow: '0px 59px 47px -27px rgba(0, 0, 0, 0.1)',
  borderBottomLeftRadius: '2px',
  borderBottomRightRadius: '2px',
  transition: `opacity ${CONTAINER_REMOVE_ANIMATION_TIME}ms linear`,
  overflow: 'hidden',
  justifyContent: 'flex-end !important',
  alignItems: 'flex-end !important',
  paddingTop: '47px',
  paddingBottom: '64px',
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,

  [theme.breakpoints.down(cssVars.headerMobileMaxWidth)]: {
    display: 'none',
  },
}));
