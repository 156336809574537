import { NodePluginArgs } from 'gatsby';

import { marketsConfig } from './config';
import { Language, Market } from './types';

export const getLanguagesForMarket = (
  market: Market,
  reporter?: NodePluginArgs['reporter'],
) => {
  let languages: Language[] = marketsConfig[market].languages.map(
    (l) => l.code,
  );

  // Market ES is a special case. The products are identical for both languages
  // (es and ca) but we want to deploy each language to its own domain. That is
  // why we allow the build process to only build one language for the ES
  // market. The build process can then build the ES market one time for each
  // language and deploy the output to a separate location.
  const esMarketLang = process.env['ES_LANG'] as Language;

  if (
    market === Market.ES &&
    typeof esMarketLang === 'string' &&
    (esMarketLang as string) !== 'undefined'
  ) {
    const langs = marketsConfig.ES.languages.map((l) => l.code);

    if (!langs.includes(esMarketLang)) {
      const errMsg = `Invalid language '${esMarketLang}' for market ES`;

      if (reporter) {
        reporter.panic(errMsg);
      } else {
        throw new Error(errMsg);
      }
    }

    // A single language will automatically remove the language prefix in the
    // URLs which is what we want in this case.
    languages = [esMarketLang];
  }

  return languages;
};
