import React, { useCallback, useEffect, useRef, useState } from 'react';

import { backdropClasses, Modal, styled, SxProps } from '@mui/material';

import { useDebouncedWindowResize } from '../../hooks/window-resize';
import cssVars from '../../styles/export-variables.module.scss';
import { IconButton, Video, VideoContainer } from './video-base';
import YoutubeVideo from './youtube-video';

type Props = {
  previewSources: {
    src: string;
    type: string;
  }[];
  mobilePreviewSources?: {
    src: string;
    type: string;
  }[];
  mobileBreakpoint?: number;
  youtubeVideo?: {
    id: string;
    title: string;
  };
  sx?: SxProps;
};

const MOBILE_BREAKPOINT = parseInt(cssVars.maxWidthVp3);

export const AutoplayVideo = ({
  previewSources,
  mobilePreviewSources,
  youtubeVideo,
  sx,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [showFullVideo, setShowFullVideo] = useState(false);
  const [isMobile, setMobile] = useState(
    ((typeof window !== 'undefined' && window.innerWidth) ||
      MOBILE_BREAKPOINT) < MOBILE_BREAKPOINT,
  );

  const onCloseFullVideo = useCallback(() => {
    setShowFullVideo(false);
  }, [setShowFullVideo]);

  const onShowFullVideo = useCallback(() => {
    setShowFullVideo(true);
  }, [setShowFullVideo]);

  const onResize = useCallback(() => {
    setMobile(window.innerWidth < MOBILE_BREAKPOINT);
  }, [setMobile]);

  useEffect(() => {
    if (videoRef.current) {
      // we need to reload the video if the sources changed
      videoRef.current.pause();
      const curTime = videoRef.current.currentTime;
      videoRef.current.load();
      videoRef.current.play();
      videoRef.current.currentTime = curTime;
    }
  }, [isMobile]);

  useDebouncedWindowResize(onResize, 200);

  useEffect(() => {
    if (videoRef.current) {
      if (showFullVideo) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  }, [videoRef, showFullVideo]);

  const sources =
    isMobile && mobilePreviewSources ? mobilePreviewSources : previewSources;

  return (
    <StyledVideoContainer sx={sx}>
      <Video ref={videoRef} autoPlay playsInline loop muted>
        {sources.map((s) => (
          <source key={s.src} src={s.src} type={s.type} />
        ))}
      </Video>

      {youtubeVideo && (
        <>
          <IconButton
            onClick={onShowFullVideo}
            className="play-icon-desktop"
            variant="text"
            startIcon={<PlayIconDesktop />}
          />
          <IconButton
            onClick={onShowFullVideo}
            className="play-icon-mobile"
            variant="text"
            startIcon={<PlayIconMobile />}
          />
        </>
      )}

      {youtubeVideo && (
        <FullVideoModal open={showFullVideo} onClose={onCloseFullVideo}>
          <React.Fragment>
            <YoutubeVideo
              videoId={youtubeVideo.id}
              title={youtubeVideo.title}
              autoplay
            />

            <CloseButton
              startIcon={<LayerCloseIcon />}
              onClick={onCloseFullVideo}
            />
          </React.Fragment>
        </FullVideoModal>
      )}
    </StyledVideoContainer>
  );
};

export default AutoplayVideo;

const StyledVideoContainer = styled(VideoContainer)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    aspectRatio: '375/356',
  },
}));

const FullVideoModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '100px',
  color: 'white',

  iframe: {
    width: '100%',
    height: '100%',
  },

  [`.${backdropClasses.root}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },

  [theme.breakpoints.down('md')]: {
    padding: '80px var(--margin)',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  right: '40px',
  top: '40px',

  [theme.breakpoints.up('md')]: {
    transform: 'scale(1.5)',
  },

  [theme.breakpoints.down('md')]: {
    right: '30px',
    top: '30px',
  },

  [theme.breakpoints.down('sm')]: {
    right: 'var(--margin)',
    top: 'var(--margin)',
  },
}));

const PlayIconDesktop = () => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="68"
      height="68"
      rx="34"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M43.3984 35.0016L30.7984 42.2762L30.7984 27.7269L43.3984 35.0016Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

const PlayIconMobile = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="48"
      height="48"
      rx="24"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M31 25L22 30.1962L22 19.8038L31 25Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

const LayerCloseIcon = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.41406"
      y="0.996094"
      width="24"
      height="2"
      transform="rotate(45 1.41406 0.996094)"
      fill="white"
    />
    <rect
      x="18.3828"
      y="2.41016"
      width="24"
      height="2"
      transform="rotate(135 18.3828 2.41016)"
      fill="white"
    />
  </svg>
);
