import { forwardRef, useRef } from 'react';

import { styled } from '@mui/material';

import { Market } from '@mewa/types';
import { cssVars, SearchInput } from '@mewa/ui';

import { marketsConfig } from '../../../libs/config/markets';
import {
  SearchPageTranslations,
  ServiceSegmentLink,
} from '../../components/header/types';
import { useSearchResult } from '../../components/search/use-search-result';
import { SearchFlyout } from './flyout';

export const HeaderSearch = forwardRef<
  HTMLDivElement,
  {
    className?: string;
    label: string;
    backButtonLabel: string;
    placeholder: string;
    translations: SearchPageTranslations;
    locationSearch: string;
    serviceSegmentLinks: ServiceSegmentLink[];
    locationKey: string;
    onSubmit: (searchTerm: string) => void;
    onSearchFocusChange: (hasFocus: boolean) => void;
    market: Market;
    langPrefix: string;
  }
>(
  (
    {
      className = '',
      backButtonLabel,
      label,
      placeholder,
      translations,
      locationSearch,
      serviceSegmentLinks,
      locationKey,
      onSubmit,
      onSearchFocusChange,
      market,
      langPrefix,
    },
    ref,
  ) => {
    const {
      results,
      totalCount,
      triggerSearch,
      clearSearch,
      trackSiteSearch,
      searchTerm,
    } = useSearchResult();
    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleSearchChange = (term: string) => {
      if (term === '') {
        clearSearch();
      } else {
        triggerDebouncedSearch(term);
      }
    };

    const triggerDebouncedSearch = (searchTerm: string) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        triggerSearch(searchTerm);
      }, 300);
    };

    const closeSearchFlyout = () => {
      if (searchTerm.trim() !== '') {
        trackSiteSearch(searchTerm, 'SearchCancelled');
      }
      onSearchFocusChange(false);
    };

    const handleEscape = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        closeSearchFlyout();
      }
    };

    const clickOnFlyoutItem = (searchCategory: string) => {
      trackSiteSearch(searchTerm, searchCategory);
    };

    const submitSearchInput = (searchTerm: string) => {
      trackSiteSearch(searchTerm, 'SearchSent');
      onSubmit(searchTerm);
    };

    return (
      <SearchContainer className={className} onKeyDown={handleEscape} ref={ref}>
        <SearchFlyoutDismissArea onClick={closeSearchFlyout} />
        <InputContainer>
          <SearchInput
            locationKey={locationKey}
            ariaLabelBackButton={backButtonLabel}
            ariaLabelButton={label}
            placeholder={placeholder}
            onSubmit={submitSearchInput}
            onChange={handleSearchChange}
            onSearchFocusChange={onSearchFocusChange}
            locationSearch={locationSearch}
          />
        </InputContainer>

        <StyledSearchFlyout
          translations={translations}
          results={results}
          totalCount={totalCount}
          serviceSegmentLinks={serviceSegmentLinks}
          isClothing={marketsConfig[market].hasClothingSegments}
          langPrefix={langPrefix}
          onClickFlyoutItem={(flyoutItemSearchCategory: string) => {
            clickOnFlyoutItem(flyoutItemSearchCategory);
          }}
        />
      </SearchContainer>
    );
  },
);

const SearchContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    height: '100%',
  },
}));

const InputContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'var(--color-white)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    height: '100%',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
}));

const SearchFlyoutDismissArea = styled('div')(({ theme }) => ({
  transition: 'background-color var(--transition-duration)',
  position: 'fixed',
  inset: 0,
  opacity: 0.4,
  pointerEvents: 'none',

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    '.search-has-focus &': {
      backgroundColor: 'var(--color-dark-coal)',
      pointerEvents: 'auto',
    },
  },
}));

const StyledSearchFlyout = styled(SearchFlyout)({
  '.search-has-focus &': {
    opacity: 1,
    visibility: 'visible',
  },
});
