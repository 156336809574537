import { useState } from 'react';

import { ReactComponent as HeartIcon } from '../../../assets/icons/heart.svg';
import styles from './like-button.module.scss';

export type LikeButtonProps = {
  isLiked: boolean;
  className?: string;
};

const LikeButton = ({ isLiked, className }: LikeButtonProps) => {
  const [liked, setLiked] = useState<boolean>(isLiked);

  return (
    <button
      className={`${styles.likeButton} ${className}`}
      onClick={() => {
        setLiked((state) => !state);
      }}
    >
      <HeartIcon className={`${styles.heart} ${liked ? styles.clicked : ''}`} />
    </button>
  );
};

export default LikeButton;
