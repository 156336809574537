import React, { PropsWithChildren } from 'react';

import { Button, ButtonTypeMap, SxProps, Theme } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { ResponsiveStyleValue } from '@mui/system';

export type ConversionButtonProps<T extends React.ElementType> = {
  display?: ResponsiveStyleValue<string[]>;
  className?: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
  variant?: string;

  link: {
    component: T;
    props: OverrideProps<ButtonTypeMap, T>;
  };
};

export const ConversionButton = <T extends React.ElementType>({
  display,
  children,
  sx: customSx = {},
  link,
  onClick,
  variant = 'outlined',
  className = '',
}: PropsWithChildren<ConversionButtonProps<T>>) => (
  <Button
    className={className}
    variant={variant}
    component={link.component}
    {...link.props}
    sx={{
      marginTop: { xs: 4, sm: 7, md: 8 },
      width: { xs: '100%', sm: 'auto' },
      display: display ?? null,
      ...customSx,
    }}
    onClick={onClick}
  >
    {children}
  </Button>
);
