import { styled } from '@mui/material';

import {
  VideoWithCaption,
  VideoWithCaptionProps,
} from '../media/video-with-caption';
import { RightGridColumn } from './base';

export const ClaimWithContentVideo = (props: VideoWithCaptionProps) => (
  <StyledRightGridColumn fullWidth>
    <VideoWithCaption {...props} />
  </StyledRightGridColumn>
);

const StyledRightGridColumn = styled(RightGridColumn)(({ theme }) => ({
  marginTop: '25px',
  marginBottom: '46px',

  [theme.breakpoints.up('sm')]: {
    marginTop: '30px',
    marginBottom: '76px',
  },

  ':last-child': {
    marginBottom: 0,

    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
}));
