import React from 'react';
import { useInView } from 'react-intersection-observer';

import { styled, SxProps } from '@mui/material';

import { ResponsiveImageProps } from '../../types';
import CardSlide from '../core/card-slide';
import styles from './image-card-slide.module.scss';

export type ImageCardSlideProps = {
  image: React.ReactElement<ResponsiveImageProps>;
  className?: string;
  aspectRatio: string;
  caption?: string;
  style?: React.CSSProperties;
  sx?: SxProps;
  icon?: React.ReactNode;
};

export const ImageCardSlide: React.FC<ImageCardSlideProps> = React.memo(
  ({ image, className, caption, style, sx, icon }) => (
    <CardSlide
      className={`${styles.imageCardSlide} ${className}`}
      style={{ ...style }}
      sx={sx}
    >
      {React.cloneElement(image, {
        className: `${image.props.className ?? ''} ${styles.imageCard}`,
      })}
      {caption && <AnimatedCaption caption={caption} />}
      {icon && <IconWrapper>{icon}</IconWrapper>}
    </CardSlide>
  ),
);

ImageCardSlide.displayName = 'SwiperSlide';

const AnimatedCaption = ({ caption }: { caption: string }) => {
  const [ref, inView] = useInView({ threshold: 1 });

  return (
    <Caption ref={ref} style={{ opacity: inView ? 1 : 0 }}>
      {caption}
    </Caption>
  );
};

const Caption = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  width: '70%',
  transition: 'opacity 0.5s linear',
  marginTop: '25px',
  marginBottom: 0,

  [theme.breakpoints.down('md')]: {
    marginTop: '15px',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  color: 'var(--color-white)',
  position: 'absolute',
  top: '20px',
  right: '20px',

  [theme.breakpoints.up('sm')]: {
    top: '30px',
    right: '30px',
  },
}));
