import { SwiperOptions } from 'swiper';

import cssVars from './../../../styles/export-variables.module.scss';

/**
 *
 * breakpoints hack:
 *   The breakpoints provided below seem to trigger a rerender of the Swiper Slides,
 *   which is needed whenever we change Slide dimensions or layout in CSS (e.g. with media-queries)
 * @returns Swiper breakpoint options that trigger a Swiper to update on
 * viewport breakpoints defined in export-variables.module.scss
 */
export const responsiveSwiperBreakpoints = () => {
  const swiperBreakpoints: { [key: number]: object } = {};
  ['maxWidthVp1', 'maxWidthVp2', 'maxWidthVp3', 'maxWidthVp4'].forEach(
    (breakpoint) => {
      swiperBreakpoints[parseInt(cssVars[breakpoint])] = {};
    },
  );
  return swiperBreakpoints as {
    [width: number]: SwiperOptions;
  };
};
