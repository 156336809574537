import { ServiceSegmentType } from '../../../libs/types';

export const allIndustries: Record<string, ServiceSegmentType[]> = {
  // Service und Gastronomie
  '8a76ee8b4ff042000150defc6afe5594': [
    ServiceSegmentType.serviceClothing,
    ServiceSegmentType.cleaningCloths,
    ServiceSegmentType.carCovers,
    ServiceSegmentType.dirtTrappingMats,
    ServiceSegmentType.oilCollectionMats,
    ServiceSegmentType.partsCleaner,
    ServiceSegmentType.washroomHygiene,
    ServiceSegmentType.wipingMops,
    ServiceSegmentType.workwear,
  ],
  // Handel und Verkauf
  '8a76eed87fad0d89018045ad58641e0f': [
    ServiceSegmentType.serviceClothing,
    ServiceSegmentType.workwear,
  ],
};
