import React, { PropsWithChildren } from 'react';

import { Grid2 as Grid, styled } from '@mui/material';

import { ResponsiveGrid } from '../../layout/grid';
import { Line } from '../lines/line';

type Props = {
  claim: string | React.ReactNode;
  introText: string;
  secondaryText?: string;
  className?: string;
  showTopDivider?: boolean;
  mobileOnlyDivider?: boolean;
  component?: keyof JSX.IntrinsicElements;
};

export const TextWithClaim = ({
  claim,
  introText,
  secondaryText,
  className,
  showTopDivider,
  mobileOnlyDivider,
  component = 'h2',
}: Props) => {
  return (
    <ResponsiveGrid className={className}>
      {showTopDivider && (
        <Grid
          offset={{ xs: 12, md: 4 }}
          size={{
            xs: 11,
            md: 7,
          }}
        >
          <Divider
            sx={{ display: { sm: mobileOnlyDivider ? 'none' : 'block' } }}
          />
        </Grid>
      )}
      <Grid
        size={{
          xs: 12,
          md: 3,
        }}
      >
        {<Claim component={component}>{claim}</Claim>}
      </Grid>
      <Grid
        sx={{ display: { xs: 'none', md: 'block' } }}
        size={{
          md: 1,
        }}
      />
      <Grid
        size={{
          xs: 11,
          md: 7,
        }}
      >
        <IntroText>{introText}</IntroText>

        {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
      </Grid>
    </ResponsiveGrid>
  );
};

// Above 1920px, the text should have a fixed width.
const textMaxWidth = '990px';

const Divider = styled(Line)(({ theme }) => ({
  margin: '0 0 70px',
  maxWidth: textMaxWidth,
  display: 'block',

  [theme.breakpoints.down('sm')]: {
    marginBottom: '20px',
  },
}));

const Claim = ({
  component,
  children,
}: PropsWithChildren & { component: keyof JSX.IntrinsicElements }) => {
  const ClaimElement = styled(component)(({ theme }) => ({
    ...theme.typography.h6,
    margin: 0,

    [theme.breakpoints.down('md')]: {
      marginBottom: '50px',
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: '22px',
    },

    [theme.breakpoints.up('md')]: {
      marginTop: '21px',
    },
  }));

  return <ClaimElement>{children}</ClaimElement>;
};

const IntroText = styled('p')(({ theme }) => ({
  ...theme.typography.h4,
  margin: 0,
  maxWidth: textMaxWidth,
}));

const SecondaryText = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  marginTop: '30px',
  marginBottom: 0,
  maxWidth: textMaxWidth,

  [theme.breakpoints.down('sm')]: {
    marginTop: '20px',
  },
}));
