import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { styled, SxProps, Theme, useMediaQuery } from '@mui/material';

import { PauseLargeIcon, PlayIcon } from '../../../assets/icons';
import { ResponsiveImageProps } from '../types';
import { IconButton, Video, VideoContainer } from './video-base';

export type VideoData = {
  posterImage: React.ReactElement<ResponsiveImageProps> | null;
  desktopSources: {
    src: string;
    type: string;
  }[];
  mobileSources?: {
    src: string;
    type: string;
  }[];
};

export type VideoWithControlProps = VideoData & {
  controls?: boolean;
  onPlay?: (isPlaying: boolean) => void;
  sx?: SxProps;
  className?: string;
  onlyPreview?: boolean;
};

enum VideoStatus {
  Stop,
  Play,
  Pause,
}
export const VideoWithControls = ({
  posterImage,
  desktopSources,
  mobileSources,
  controls = true,
  onPlay,
  sx,
  children,
  className = '',
  onlyPreview = false,
}: PropsWithChildren<VideoWithControlProps>) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [status, setStatus] = useState(VideoStatus.Stop);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const onStartVideo = useCallback(() => {
    setStatus(VideoStatus.Play);
  }, [setStatus]);

  const onPauseVideo = useCallback(() => {
    setStatus(VideoStatus.Pause);
  }, [setStatus]);

  useEffect(() => {
    if (videoRef.current) {
      if (status === VideoStatus.Play) {
        videoRef.current.play();
        if (onPlay) {
          onPlay(true);
        }
      } else {
        videoRef.current.pause();
        if (onPlay) {
          onPlay(false);
        }
      }
    }
  }, [videoRef, status, onPlay]);

  const sources = isMobile && mobileSources ? mobileSources : desktopSources;

  const hideVideo = status === VideoStatus.Stop && posterImage;

  return (
    <VideoContainer sx={sx} className={className}>
      <Video
        ref={videoRef}
        playsInline
        controls={controls}
        style={{
          visibility: hideVideo ? 'hidden' : undefined,
          maxHeight: hideVideo ? 0 : undefined,
        }}
        onPlay={() => {
          if (!onlyPreview) {
            onStartVideo();
          }
        }}
        onPause={() => {
          onPauseVideo();
        }}
      >
        {sources.map((s) => (
          <source key={s.src} src={s.src} type={s.type} />
        ))}
      </Video>
      {children}
      {status !== VideoStatus.Play ? (
        <>
          {status === VideoStatus.Stop ? (
            <PosterImage>{posterImage}</PosterImage>
          ) : null}
          <PlayButton
            onClick={() => {
              if (!onlyPreview) {
                onStartVideo();
              }
            }}
            variant="text"
            startIcon={<PlayIcon />}
          />
        </>
      ) : !controls ? (
        <PlayButton
          onClick={onPauseVideo}
          variant="text"
          className="pause-button"
          startIcon={<PauseLargeIcon />}
        />
      ) : null}
    </VideoContainer>
  );
};

export default VideoWithControls;

const PosterImage = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',

  '.gatsby-image-wrapper': {
    height: '100%',
  },
});

const PlayButton = styled(IconButton)(({ theme }) => ({
  height: '60px',
  width: '60px',
  zIndex: '1',
  display: 'block',

  [theme.breakpoints.up('md')]: {
    height: '105px',
    width: '105px',
  },

  svg: {
    transition:
      'transform var(--transition-duration), color var(--transition-duration)',
  },

  ':hover svg': {
    transform: 'scale(1.05)',
    color: 'var(--color-light-red)',
  },
}));
