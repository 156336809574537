import React, { useState } from 'react';
import { useSwiper } from 'swiper/react';

import { Controls } from './controls';

export const AnimationControls = () => {
  const [isPaused, setIsPaused] = useState(false);
  const swiper = useSwiper();

  const playSlider = () => {
    swiper.autoplay.run();
    setIsPaused(false);
  };

  const pauseSlider = () => {
    swiper.autoplay.pause();
    setIsPaused(true);
  };

  return <Controls isPaused={isPaused} pause={pauseSlider} play={playSlider} />;
};
