import React, { useEffect, useRef, useState } from 'react';

import { styled, Theme } from '@mui/material';

import { ArrowLeftIcon, ClearIcon } from '../../../assets/icons';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search-icon.svg';
import cssVars from '../../styles/export-variables.module.scss';

type Props = {
  className?: string;
  placeholder: string;
  ariaLabelBackButton: string;
  ariaLabelButton: string;
  locationSearch: string;
  locationKey: string;
  onSubmit: (value: string) => void;
  onChange?: (value: string) => void;
  onSearchFocusChange: (hasFocus: boolean) => void;
};

export const SearchInput = React.memo(
  ({
    className = '',
    placeholder,
    ariaLabelBackButton,
    ariaLabelButton,
    locationSearch,
    locationKey,
    onSubmit,
    onChange,
    onSearchFocusChange,
  }: Props) => {
    const queryParam = new URLSearchParams(locationSearch).get('q');

    const [input, setInput] = useState<string>(queryParam ?? '');
    const inputRef = useRef<HTMLInputElement>(null);
    const currentLocationKeyRef = useRef(locationKey);

    const clearInput = () => {
      setInput('');

      if (onChange) {
        onChange('');
      }
    };

    useEffect(() => {
      // Only display the search term input value when the query param is set (on search result page)
      if (locationKey !== currentLocationKeyRef.current) {
        currentLocationKeyRef.current = locationKey;
        setInput(queryParam ?? '');
      }
    }, [locationKey, locationSearch, queryParam]);

    const handleClear = () => {
      clearInput();

      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleBack = () => {
      clearInput();
      onSearchFocusChange(false);
    };

    const handleSubmit = (event: React.FormEvent) => {
      if (inputRef.current) {
        inputRef.current.blur();
      }

      if (input.trim() !== '') {
        onSearchFocusChange(false);
        onSubmit(input);
      }

      event.preventDefault();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);

      if (onChange) {
        onChange(e.target.value);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        onSearchFocusChange(false);

        if (inputRef.current) {
          inputRef.current.blur();
        }
      }
    };

    return (
      <SearchForm onSubmit={handleSubmit} className={className}>
        <BackButton
          type="button"
          aria-label={ariaLabelBackButton}
          onClick={handleBack}
        >
          <ArrowLeftIcon />
        </BackButton>
        <StyledInput
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          ref={inputRef}
          onFocus={() => onSearchFocusChange(true)}
          type="text"
          placeholder={placeholder}
          value={input}
        />
        <ClearButton
          aria-label={ariaLabelButton}
          onClick={handleClear}
          className="clear"
          type="button"
        >
          <ClearIcon />
        </ClearButton>
        <SearchButton type="submit" aria-label={ariaLabelButton}>
          <CustomSearchIcon />
        </SearchButton>
      </SearchForm>
    );
  },
);

const SearchForm = styled('form')({
  whiteSpace: 'nowrap',
  position: 'relative',
});

const buttonWidth = '60px';
const clearButtonWidth = '40px';
const searchInputMobileHeight = '46px';
const searchInputHeight = '50px';
const buttonOuterPadding = '16px';

const buttonStyles = (theme: Theme): object => ({
  position: 'absolute',
  backgroundColor: 'transparent',
  border: 'none',
  height: searchInputMobileHeight,
  width: buttonWidth,
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    transition: 'color var(--transition-duration)',
  },

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    height: searchInputHeight,
  },
});

const BackButton = styled('button')(({ theme }) => ({
  ...buttonStyles(theme),
  left: 0,
  paddingLeft: buttonOuterPadding,
  display: 'none',

  svg: {
    width: '9px',
    height: '12px',
    color: 'var(--color-dark-coal)',
  },

  [theme.breakpoints.down(cssVars.mobileSearchMaxWidth)]: {
    '.search-has-focus &': {
      display: 'inline-flex',
    },
  },
}));

const ClearButton = styled('button')(({ theme }) => ({
  ...buttonStyles(theme),
  right: buttonWidth,
  width: clearButtonWidth,
  display: 'none',

  svg: {
    width: '14px',
    height: '14px',
    color: 'var(--color-dark-coal)',
  },

  '::after': {
    content: '""',
    display: 'block',
    width: '1px',
    height: '22px',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'var(--color-medium-light-grey)',
  },
}));

const SearchButton = styled('button')(({ theme }) => ({
  ...buttonStyles(theme),
  right: 0,
  paddingRight: buttonOuterPadding,
  pointerEvents: 'none',
}));

const CustomSearchIcon = styled(SearchIcon)(() => ({
  color: 'var(--color-medium-grey)',
}));

const StyledInput = styled('input')<{ value: string }>(({ theme, value }) => ({
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightMedium,
  paddingTop: '12px',
  paddingRight:
    value === '' ? buttonWidth : `calc(${buttonWidth} + ${clearButtonWidth})`,
  paddingBottom: '12px',
  paddingLeft: `calc(${searchInputMobileHeight} / 2)`,
  width: '100%',
  height: searchInputMobileHeight,
  backgroundColor: 'var(--color-light-grey)',
  border: '1px solid transparent',
  borderRadius: `calc(${searchInputMobileHeight} / 2)`,
  outline: 'none',
  color: 'var(--color-dark-coal)',

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    height: searchInputHeight,
    borderRadius: `calc(${searchInputHeight} / 2)`,
  },

  '&:hover, &:focus': {
    backgroundColor: 'white',
    border: '1px solid var(--color-light-red)',
  },

  '&:not(:placeholder-shown)': {
    '~ button[type=submit]': {
      pointerEvents: 'all',

      svg: {
        color: 'var(--color-dark-coal)',
      },

      ':hover svg, :focus svg': {
        color: 'var(--color-light-red)',
      },
    },
    '+ button.clear': {
      display: 'inline-flex',
    },
  },

  [theme.breakpoints.down(cssVars.mobileSearchMaxWidth)]: {
    '.search-has-focus &': {
      paddingLeft: buttonWidth,
    },
  },
}));

export default SearchInput;
