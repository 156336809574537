import { MewaStepsIcon } from '@mewa/types';

import { ReactComponent as CalendarMts } from './01mts.svg';
import { ReactComponent as CleanAndMaintain } from './02mbs_mts.svg';
import { ReactComponent as TruckMbs } from './03mbs.svg';
import { ReactComponent as TruckMts } from './03mts.svg';
import { ReactComponent as CalendarMbs } from './dirty_cloths_calendar.svg';

export const mewaStepsIconMap: Record<
  MewaStepsIcon,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  [MewaStepsIcon.CalendarMbs]: CalendarMbs,
  [MewaStepsIcon.CalendarMts]: CalendarMts,
  [MewaStepsIcon.TruckMbs]: TruckMbs,
  [MewaStepsIcon.TruckMts]: TruckMts,
  [MewaStepsIcon.CleanAndMaintain]: CleanAndMaintain,
};

export { CalendarMts, CleanAndMaintain, TruckMbs, TruckMts, CalendarMbs };
