import React, { useEffect } from 'react';

import {
  buttonBaseClasses,
  styled,
  Tab,
  tabClasses,
  Tabs,
  tabsClasses,
} from '@mui/material';

export type LinkTabsProps<LinkProps> = {
  links: ({ label: string } & LinkProps)[];
  LinkComponent: React.ElementType<LinkProps>;
  selectedIndex: number;
  className?: string;
};

export const LinkTabs = <T,>({
  links,
  LinkComponent,
  selectedIndex,
  className,
}: React.PropsWithChildren<LinkTabsProps<T>>) => {
  useEffect(() => {
    const lastScrollY = localStorage.getItem('scrollPosition');

    // scroll to last position after filtering and then reset the scroll position
    if (lastScrollY !== null && !isNaN(parseInt(lastScrollY))) {
      window.scrollTo({ top: parseInt(lastScrollY) });
      localStorage.removeItem('scrollPosition');
    }
  }, []);

  return (
    <StyledTabs
      value={selectedIndex}
      className={className}
      TabIndicatorProps={{ style: { display: 'none' } }}
    >
      {links.map(({ label, ...rest }, idx) =>
        idx === selectedIndex ? (
          <StyledTab disableRipple key={label} label={label} />
        ) : (
          <StyledTab
            onClick={() => {
              localStorage.setItem('scrollPosition', window.scrollY.toString());
            }}
            disableRipple
            key={label}
            LinkComponent={LinkComponent}
            {...rest}
            label={label}
          />
        ),
      )}
    </StyledTabs>
  );
};

export default LinkTabs;

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`.${tabsClasses.indicator}`]: {
    bottom: '3px',
  },

  [theme.breakpoints.down('sm')]: {
    [`.${tabsClasses.flexContainer}`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },

    [`.${tabsClasses.indicator}`]: {
      display: 'none',
    },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  marginRight: '40px',
  color: 'var(--color-dark-coal)',
  textAlign: 'left',
  alignItems: 'flex-start',
  minWidth: 0,
  transition: 'color 0.2s ease',

  '&:hover': {
    color: 'var(--color-light-red)',
  },

  [`&.${tabClasses.selected}`]: {
    cursor: 'unset',
  },

  [`&.${buttonBaseClasses.root}`]: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '26px',
      paddingBottom: '26px',
    },
  },
}));
