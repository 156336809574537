import React from 'react';

import { PageProps } from 'gatsby';

import { Market } from '@mewa/types';
import { KeyboardControlledDebugGrid, Theme } from '@mewa/ui';
import '@mewa/ui/lib/styles/ui-lib.scss';

import { NavigationData } from '../src/components/header/types';
import PiwikTracker from '../src/components/piwik-tracker';
import PageLayout from '../src/layouts/page-layout';
import { LayoutFlags, MetaProps } from '../src/layouts/types';
import { GlobalPageContext } from '../src/types';

type ThemeWrapperProps = {
  element: React.ReactElement;
};

export type LayoutPageContext = GlobalPageContext & {
  market: Market;
  title?: string;
  description?: string;
  keywords?: string[];
  alternatives: MetaProps['alternatives'];
  piwikEventNameForHeaderCTAButton?: string;
};

export type LayoutLocationData = {
  key?: string;
};

type LayoutWrapperProps = {
  // The component that is rendering the page can have additional properties that are specified as LayoutFlags.
  element: React.ReactElement & {
    type: LayoutFlags;
  };
  props: PageProps<NavigationData, LayoutPageContext>;
};

type Wrapper<T> = (args: T) => React.ReactElement;

export const themeWrapper: Wrapper<ThemeWrapperProps> = ({ element }) => {
  return <Theme>{element}</Theme>;
};

/**
 * Wraps the entire page with the Layout component which includes the header
 * with the main navigation. This assumes that every page component includes
 * the `...NavigationFragment` in the exported graqhql query.
 */
export const layoutWrapper: Wrapper<LayoutWrapperProps> = ({
  element,
  props: { pageContext, data, location },
}) => {
  return (
    <React.Fragment>
      <PiwikTracker
        locationKey={location?.key || ''}
        pageContext={pageContext}
      />
      <PageLayout
        locationKey={location?.key || ''}
        lang={pageContext.lang}
        langPrefix={pageContext.langPrefix}
        market={pageContext.market}
        navigationData={data}
        layoutFlags={{
          layout: element.type.layout,
          noContactTeaser: element.type.noContactTeaser,
        }}
        piwikEventNameForHeaderCTAButton={
          pageContext.piwikEventNameForHeaderCTAButton
        }
        serviceSegment={pageContext.serviceSegment}
        pageType={pageContext.pageType}
        locationSearch={location.search}
        featureDev={pageContext.featureDev}
      >
        {element}
      </PageLayout>
    </React.Fragment>
  );
};
export const layoutWrapperWithDebugGrid: Wrapper<LayoutWrapperProps> = (
  args,
) => (
  <KeyboardControlledDebugGrid>
    {layoutWrapper(args)}
  </KeyboardControlledDebugGrid>
);
