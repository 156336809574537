import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import { Button, buttonClasses, Grid2 as Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../../layout/grid';
import { MarkdownContainer } from '../../markdown/markdown-container';
import { ConversionTeaserProps } from '../conversion-teaser/conversion-teaser';
import { ConversionTeaserBase } from '../types';

export type ConversionTeaserImageProps<T extends React.ElementType> = Omit<
  ConversionTeaserBase<T> & ConversionTeaserProps,
  'headline'
> & {
  headline: React.ReactElement | string;
  onClick?: () => void;
  className?: string;
};

export const ConversionTeaserImage = <T extends React.ElementType>({
  headline,
  labelCTA,
  piwikEventNameCTA,
  content,
  state,
  sx,
  image,
  link,
  onClick,
  className = '',
}: ConversionTeaserImageProps<T>) => {
  return (
    <Container sx={sx} className={className}>
      <ImageContainer>
        {image}
        <GradientContainer />
      </ImageContainer>
      <TeaserResponsiveGrid
        gridProps={{ sx: { height: '100%', justifyContent: 'flex-end' } }}
      >
        <TeaserGrid size={{ xs: 12, sm: 6 }}>
          <div>
            <Headline>{headline}</Headline>
            <ContentWrapper html={content} />
            <ButtonStyleWrapper>
              <Button
                variant="contained"
                color="primary"
                component={link.component}
                {...link.props}
                state={state}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }
                  DataLayer.push({
                    event: piwikEventNameCTA || '',
                  });
                }}
              >
                {labelCTA}
              </Button>
            </ButtonStyleWrapper>
          </div>
        </TeaserGrid>
      </TeaserResponsiveGrid>
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: '60px',
  marginBottom: '70px',

  [theme.breakpoints.up('sm')]: {
    '.gatsby-image-wrapper': {
      height: '100%',
    },
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '70px',
    marginBottom: '80px',
  },

  [theme.breakpoints.up('lg')]: {
    marginTop: '80px',
    marginBottom: '100px',
  },
}));

const ImageContainer = styled('div')(({ theme }) => ({
  lineHeight: 0,

  [theme.breakpoints.up('sm')]: {
    aspectRatio: '900 / 450',

    '.gatsby-image-wrapper': {
      height: '100%',
    },
  },

  [theme.breakpoints.up('md')]: {
    aspectRatio: '1440 / 600',
  },

  [theme.breakpoints.up('md')]: {
    aspectRatio: '1920 / 700',
  },
}));

const GradientContainer = styled('div')(({ theme }) => ({
  background:
    'linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%)',
  inset: '0 0 0 50%',
  position: 'absolute',
  opacity: 0.5,
  right: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const TeaserResponsiveGrid = styled(ResponsiveGrid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
}));

const TeaserGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    height: 'calc(100% - 50px)',
    marginTop: '50px',
  },

  [theme.breakpoints.up('mdSm')]: {
    marginTop: '70px',
    height: 'calc(100% - 70px)',
  },

  [theme.breakpoints.up('lg')]: {
    marginTop: '100px',
    height: 'calc(100% - 100px)',
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: '16px',
  marginBottom: '5px',

  strong: {
    fontWeight: theme.typography.fontWeightMedium,
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: '8px',
    color: 'var(--color-white)',
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '0px',
    marginBottom: '10px',
  },

  [theme.breakpoints.up('lg')]: {
    marginBottom: '12px',
  },
}));

const ButtonStyleWrapper = styled('div')(({ theme }) => ({
  [`.${buttonClasses.contained}`]: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '18px',
    },
  },
}));

const ContentWrapper = styled(MarkdownContainer)(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: theme.typography.fontWeightRegular,
  marginBottom: '20px',
  paddingRight: '30px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '30px',
    padding: 0,
    color: 'var(--color-white)',
  },
}));
