import React from 'react';

import 'swiper/css';
import 'swiper/css/pagination';

import { styled, useMediaQuery, useTheme } from '@mui/material';

import {
  MobileSlider,
  MobileSliderProps,
} from '../mobile-slider/mobile-slider';
import { Award, AwardData } from './award';

type AwardsProps = {
  awards: AwardData[];
  className?: string;
  slidesPerView?: MobileSliderProps['slidesPerView'];
};

export const Awards = ({
  awards,
  className = '',
  slidesPerView,
}: AwardsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const awardsChildren = awards.map((award, idx) => (
    <Award award={award} key={idx} />
  ));

  return (
    <div className={className}>
      {isMobile ? (
        <MobileSlider slidesPerView={slidesPerView}>
          {awardsChildren}
        </MobileSlider>
      ) : (
        <Container>{awardsChildren}</Container>
      )}
    </div>
  );
};

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: 'var(--gutter)',
  justifyContent: 'space-around',
  margin: '0 auto',

  [theme.breakpoints.up('md')]: {
    width: '83.334%',
  },
}));
